// IMPORTS
import {
    useState,
    useEffect,
    Dispatch,
    SetStateAction,
    useContext
} from 'react';
import { TableRow, IconButton } from '@mui/material';
import {
    RadioButtonUnchecked,
    CheckCircle,
    Check,
    Add
} from '@mui/icons-material';

// COMPONENTS
import TableSearch from '../../../global/tableComponents/TableSearch';
import Paper from '../../../global/Paper';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import Fab from '../../../global/Fab';
import Drawer from '../../../global/Drawer';
import ManufacturerVehicleDrawerContent from '../../manufacturerVehicleTable/components/ManufacturerVehicleDrawerContent';
// LOGIC
import { CurrencyFormatter } from '../../../global/logic/Formatters';
// INTERFACES
import {
    ManufacturerVehicle,
    Vehicle
} from '../../../global/interfaces/VehicleSaleInterface';
import { Site } from '../../../global/interfaces/GeneralInterface';
import { SiteContext } from '../../../../main/Main';
import dayjs from 'dayjs';
interface ManufacturerVehicleFormProps {
    readOnly: boolean;
    selectedEntity: Vehicle;
    setSelectedEntity: Dispatch<SetStateAction<Vehicle>>;
}

// Default values for the filter and sort for initial set state and clear filter
const clearedSort = ['id', 'DESC'];

const ManufacturerVehicleForm = ({
    readOnly,
    selectedEntity,
    setSelectedEntity
}: ManufacturerVehicleFormProps) => {
    const siteDetails = useContext<Site>(SiteContext);
    const [resultsList, setResultsList] = useState<Vehicle[]>([]);
    const [resultsListAltered, setResultsListAltered] = useState<Vehicle[]>([]);
    const [vehicleDrawerOpen, setVehicleDrawerOpen] = useState<boolean>(false);
    const [selectedManufacturerVehicle, setSelectedManufacturerVehicle] =
        useState<ManufacturerVehicle>({});

    // Columns for the data table
    const columns =
        siteDetails.vehicleTypeSold === 'Motorcycles'
            ? [
                  { id: 0, label: '', width: 100 },
                  { id: 1, label: 'ID', width: 50 },
                  { id: 2, label: 'Vehicle', width: 300 },
                  { id: 3, label: 'RRP', width: 100 },
                  { id: 4, label: 'Colour', width: 100 },
                  { id: 5, label: 'Learner Approved', width: 100 }
              ]
            : [
                  { id: 0, label: '', width: 100 },
                  { id: 1, label: 'ID', width: 50 },
                  { id: 2, label: 'Vehicle', width: 300 },
                  { id: 3, label: 'RRP', width: 100 },
                  { id: 4, label: 'Colour', width: 100 }
              ];

    useEffect(() => {
        if (selectedEntity?.id) {
            let singleRow = [];
            singleRow.push(selectedEntity);
            setResultsListAltered(singleRow);
        } else {
            setResultsListAltered(resultsList);
        }
    }, [selectedEntity, resultsList]);

    useEffect(() => {
        if (!vehicleDrawerOpen && selectedManufacturerVehicle?.id) {
            let currentResults = [...resultsList];
            let index = currentResults.findIndex(
                (x) => x.id === selectedManufacturerVehicle?.id
            );
            currentResults[index] = selectedManufacturerVehicle;
            setResultsList(currentResults);
        }
        // eslint-disable-next-line
    }, [vehicleDrawerOpen]);

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        return '';
    };

    const getVehicleString = (row: ManufacturerVehicle) => {
        let vehicleString = '';

        if (row?.year) {
            const year = dayjs(row?.year).year();
            vehicleString += `${year} `;
        }
        vehicleString += `${row?.Make?.name} ${row?.Model?.name}`;
        if (row?.SeriesId) {
            vehicleString += ` ${row?.Series?.name}`;
        }

        return vehicleString;
    };

    return (
        <>
            <TableSearch
                searchTitle="Search Manufacturer Vehicles"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                sort={clearedSort}
                handleRequestCreate={handleRequestCreate}
                route="manufacturerVehicleSearch"
                setResultsList={setResultsList}
                customLimit={10}
                disableSearch={resultsListAltered.length === 1 ? true : false}
            />

            <Paper>
                <DataTable columns={columns}>
                    {resultsListAltered.map((row) => (
                        <TableRow key={row.id}>
                            <DataCell>
                                {resultsListAltered.length === 1 &&
                                selectedEntity?.id === row.id ? (
                                    <IconButton
                                        onClick={() => {
                                            setSelectedEntity(null);
                                            setSelectedManufacturerVehicle(
                                                null
                                            );
                                        }}
                                        disabled={readOnly}
                                    >
                                        <CheckCircle />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={() => setSelectedEntity(row)}
                                    >
                                        <RadioButtonUnchecked />
                                    </IconButton>
                                )}
                            </DataCell>
                            <DataCellColoured
                                handleClick={() => {
                                    setSelectedManufacturerVehicle(row);
                                    setVehicleDrawerOpen(true);
                                }}
                            >
                                {row.id}
                            </DataCellColoured>
                            <DataCell>
                                {row.year ? dayjs(row.year).year() : ''}{' '}
                                {row.Make?.name} {row.Model?.name}{' '}
                                {row.Series?.name}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(row.priceRRP)}
                            </DataCell>
                            <DataCell>{row.colour?.toUpperCase()}</DataCell>
                            {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                                <DataCell>
                                    {row.learnerApproved ? <Check /> : null}
                                </DataCell>
                            ) : null}
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            {selectedEntity?.id ? null : (
                <Fab
                    customIcon={<Add />}
                    onClick={() => setVehicleDrawerOpen(true)}
                    noSession={true}
                />
            )}

            <Drawer
                openDrawer={vehicleDrawerOpen}
                setOpenDrawer={setVehicleDrawerOpen}
                title={
                    selectedManufacturerVehicle?.id
                        ? getVehicleString(selectedManufacturerVehicle)
                        : 'New Manufacturer Vehicle'
                }
                subTitle={''}
            >
                <ManufacturerVehicleDrawerContent
                    selectedManufacturerVehicle={selectedManufacturerVehicle}
                    setSelectedManufacturerVehicle={
                        setSelectedManufacturerVehicle
                    }
                    setSelectedEntity={setSelectedEntity}
                    noRefresh={true}
                    setVehicleDrawerOpen={setVehicleDrawerOpen}
                />
            </Drawer>
        </>
    );
};

export default ManufacturerVehicleForm;
