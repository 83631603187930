import { LoadingButton } from '@mui/lab';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Grid
} from '@mui/material';
import { useEffect, useState } from 'react';

interface DealerDocumentActionDialogProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    status: string;
    type: string;
    handleClickView: () => void;
    handleClickStartOver: () => void;
}

const DealerDocumentActionDialog = ({
    dialogOpen,
    setDialogOpen,
    status,
    type,
    handleClickView,
    handleClickStartOver
}: DealerDocumentActionDialogProps) => {
    const [loadingButton, setLoadingButton] = useState<string | null>(null);

    useEffect(() => {
        setLoadingButton(null);
    }, [dialogOpen]);

    const isWaitingSignature = status === 'waitingSignature';
    const isTradeInFormsSigned =
        status === 'vendorSigned' && type === 'tradeIn';
    const isContractSigned = status === 'customerSigned' && type === 'contract';
    const isPersonalisedPlateSigned =
        status === 'customerSigned' && type === 'personalisedPlate';
    const isDeliverySigned = status === 'deliverySigned' && type === 'contract';

    return (
        <Dialog
            open={dialogOpen && status !== null}
            onClose={() => setDialogOpen(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Email Document To Sign </DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    {isWaitingSignature &&
                        'The document has been emailed to the customer for signature.'}
                    {isTradeInFormsSigned &&
                        'The document has been signed by the vendor.'}
                    {isContractSigned &&
                        'The contract Credit and Declaration sections have been signed by the customer.'}
                    {isPersonalisedPlateSigned &&
                        'The personalised plate form have been signed by the customer.'}
                    {isDeliverySigned && 'Delivery Receipt has been signed.'}
                </Typography>
                <Typography variant="subtitle2">
                    <br />
                    {`Click View Document to review the document ${isContractSigned ? ' and/or email the Delivery Receipt to the customer for their signature' : ''}.`}
                    <br />
                    Click Start Over to update the document and send a new
                    email.{' '}
                    <b>
                        Note this will erase all previous changes on the
                        document.
                    </b>
                </Typography>

                <br />

                <Grid container spacing={2}>
                    <Grid item xs={6} textAlign="center">
                        <LoadingButton
                            loading={loadingButton === 'startOver'}
                            variant="outlined"
                            onClick={() => {
                                setLoadingButton('startOver');
                                handleClickStartOver();
                            }}
                        >
                            Start Over
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={6} textAlign="center">
                        <LoadingButton
                            loading={loadingButton === 'viewDocument'}
                            variant="contained"
                            onClick={() => {
                                setLoadingButton('viewDocument');
                                handleClickView();
                            }}
                        >
                            View Document
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DealerDocumentActionDialog;
