import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { PDFDocument } from 'pdf-lib';
import PageWrapper from '../../global/PageWrapper';
import { useEffect, useState } from 'react';
import { DEFAULT_PERSONALISED_PLATE_FORM_VALUES } from '../../esign/DefaultFormValues';
import { useParams } from 'react-router-dom';
import api from '../../../../api';
import { EmailSignUrl } from '../signContract/logic/EmailContract';
import {
    GetFileUrlFromS3,
    UploadFileToS3
} from '../../esign/logic/S3FileService';
import DealerDocumentStatusHeader from '../../esign/components/DocumentStatusHeader';
import DealerPersonalisedPlateForm from '../signVendorStatement/components/dealerForms/DealerPersonalisedPlateForm';
import renderDealerPlatePdf from '../signVendorStatement/logic/renderDealerPlatePdf';

const DealerSignPersonalisedPlateForm = ({
    customerInfo,
    personalisedPlateStatus,
    saleDetails,
    showSnackbar
}) => {
    const { id: vehicleSaleId } = useParams<{ id: string }>();

    const [pdfDoc, setPdfDoc] = useState<PDFDocument>(null);
    const [pdfUrl, setPdfUrl] = useState<string>(null);
    const [formData, setFormData] = useState(
        DEFAULT_PERSONALISED_PLATE_FORM_VALUES
    );

    const getPrefilledPersonalisedPlate = async () => {
        try {
            const result = await api.post('/prefilledPersonalisedPlateForm', {
                saleDetails
                // dutyPrice
            });
            const base64PDF = result.data;

            if (typeof base64PDF !== 'string') {
                throw new Error('Expected a base64 string');
            }

            const pdfBytes = Uint8Array.from(atob(base64PDF), (c) =>
                c.charCodeAt(0)
            );

            const pdfDoc = await PDFDocument.load(pdfBytes);
            setPdfDoc(pdfDoc);
            const newPdfUrl = URL.createObjectURL(
                new Blob([pdfBytes], { type: 'application/pdf' })
            );
            setPdfUrl(newPdfUrl);
        } catch (error) {
            console.error('Failed to fetch PDF: ', error);
            showSnackbar(
                'Failed to fetch PDF',
                'Please try again later',
                'error'
            );
        }
    };

    const getFileUrl = async () => {
        const url = await GetFileUrlFromS3(
            'vehicleSalePersonalisedPlate',
            `${vehicleSaleId}`
        );
        setPdfUrl(url);
    };

    useEffect(() => {
        const getPersonalisedPlateDocument = async () => {
            if (
                personalisedPlateStatus === 'customerSigned' ||
                personalisedPlateStatus === 'waitingSignature'
            ) {
                await getFileUrl();
            } else {
                await getPrefilledPersonalisedPlate();
            }
        };

        getPersonalisedPlateDocument();
        // eslint-disable-next-line
    }, [personalisedPlateStatus]);

    const baseURL = window.location.origin;
    let customerSignUrl = `${baseURL}/esign/personalised_plate_form/${vehicleSaleId}`;

    const handleEmailSignUrl = (pdfBlob) => {
        // upload personalisedPlate to S3
        const reader = new FileReader();
        reader.readAsDataURL(pdfBlob);
        reader.onloadend = () => {
            const base64data = reader.result as string;
            UploadFileToS3({
                base64data: base64data,
                folderName: `vehicleSalePersonalisedPlate`,
                fileName: `${vehicleSaleId}`
            });
        };

        EmailSignUrl({
            type: 'personalisedPlate',
            id: parseInt(vehicleSaleId),
            customerSignUrl: customerSignUrl,
            newStatus: 'waitingSignature',
            showSnackbar: showSnackbar
        });
    };

    const onDealerPersonalisedPlateForm = async (
        formData: any,
        submitType: string
    ) => {
        if (!pdfDoc) throw new Error('PDF document not loaded');
        // Make a copy of original document, so everytime formData changes, we start from the original document
        const pdfDocCopy = await PDFDocument.load(await pdfDoc.save());

        const pdfBytes = await renderDealerPlatePdf(pdfDocCopy, formData);

        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

        if (submitType === 'preview') {
            setPdfUrl(URL.createObjectURL(pdfBlob));
        }

        // Upload the updated PDF to S3
        if (submitType === 'upload') {
            handleEmailSignUrl(pdfBlob);
        }
    };

    return (
        <PageWrapper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DealerDocumentStatusHeader
                        documentType="Personalised Plate"
                        documentId={vehicleSaleId}
                        documentStatus={personalisedPlateStatus}
                    />
                </Grid>
                {!personalisedPlateStatus && (
                    <Grid item xs={12} sm={6}>
                        <DealerPersonalisedPlateForm
                            type="vehicleSale"
                            formData={formData}
                            setFormData={setFormData}
                            onDealerPersonalisedPlateForm={
                                onDealerPersonalisedPlateForm
                            }
                            customer={customerInfo}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={personalisedPlateStatus ? 12 : 6}>
                    {pdfUrl ? (
                        <Box width="100%" height="200vh">
                            <iframe
                                src={pdfUrl + '#view=Fit'}
                                width="100%"
                                height="100%"
                                title="PDF Document"
                            >
                                Loading PDF...
                            </iframe>
                        </Box>
                    ) : (
                        <Box width="100%" height="100%" textAlign={'center'}>
                            {personalisedPlateStatus ? (
                                <Typography variant="h5">
                                    No File Found
                                </Typography>
                            ) : (
                                <>
                                    <CircularProgress />
                                    <Typography variant="h5">
                                        Loading form...
                                    </Typography>
                                </>
                            )}
                        </Box>
                    )}
                </Grid>
            </Grid>
        </PageWrapper>
    );
};

export default DealerSignPersonalisedPlateForm;
