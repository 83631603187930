import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import { DeletePoll } from '../logic/HandlePoll';

const DeletePollDialog = ({ open, onClose, pollId, showSnackbar }) => {
    const handleDelete = async () => {
        await DeletePoll({
            pollId: pollId,
            showSnackbar
        });
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Delete Poll</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete the poll? All votes and data
                    will be lost.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined">
                    Cancel
                </Button>
                <Button onClick={handleDelete} variant="contained">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeletePollDialog;
