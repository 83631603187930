import api from '../../../../../api';
import {
    ValidateEmpty,
    ValidateOdometer,
    ValidateRRP,
    ValidateVin,
    ValidateYear
} from '../../../global/logic/vehicleValidation/ValidateVehicleFields';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';

const HandleUpdateVehicle = (vehicle: Vehicle, showSnackbar: showSnackbar) => {
    let filledFields = ValidateEmpty([
        vehicle?.MakeId,
        vehicle?.ModelId,
        vehicle?.year,
        vehicle?.vin,
        vehicle?.priceRRP
    ]);

    let validVin = ValidateVin(vehicle?.vin);
    let validYear = ValidateYear(vehicle?.year);

    if (!filledFields) {
        showSnackbar(
            'Missing data!',

            'Make, Model, Year, VIN and RRP are required, please ensure they are entered.',
            'error'
        );
    } else if (!ValidateOdometer(vehicle?.odometer)) {
        showSnackbar(
            'Invalid Odometer!',
            'Odometer needs to be a number.',
            'error'
        );
    } else if (!ValidateRRP(parseFloat(vehicle?.priceRRP))) {
        showSnackbar('Invalid RRP.', 'RRP needs to be a valid number', 'error');
    } else if (validVin !== 'valid') {
        showSnackbar('Invalid VIN!', validVin, 'error');
    } else if (validYear !== 'valid') {
        showSnackbar('Invalid Year!', validYear, 'error');
    } else {
        api.put(`updateVehicle`, vehicle).then((res) => {
            if (res.status === 200) {
                showSnackbar(
                    'Vehicle Updated Successfully',
                    'The page will now refresh'
                );
                window.location.reload();
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        });
    }
};

export default HandleUpdateVehicle;
