import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Poll } from '../../../global/interfaces/PollInterface';

interface CreateNewPollProps {
    question: string;
    options: string[];
    showSnackbar: showSnackbar;
}

export const CreateNewPoll = async ({
    question,
    options,
    showSnackbar
}: CreateNewPollProps) => {
    try {
        const response = await api.post('newPoll', {
            question: question,
            options: options
        });

        if (response.status === 200) {
            showSnackbar(
                'Poll created successfully',
                'The page will now refresh',
                'success'
            );

            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } else {
            showSnackbar('Error creating poll', 'Please try again', 'error');
        }

        return response.data;
    } catch (error) {
        showSnackbar('Error creating poll', 'Please try again', 'error');
    }
};

interface UpdatePollProps {
    pollId: number;
    question: string;
    options: string[];
    showSnackbar: showSnackbar;
}

export const UpdatePoll = async ({
    pollId,
    question,
    options,
    showSnackbar
}: UpdatePollProps) => {
    try {
        const response = await api.put(`updatePoll/${pollId}`, {
            question: question,
            options: options
        });

        if (response.status === 200) {
            showSnackbar(
                'Poll updated successfully',
                'The page will now refresh',
                'success'
            );

            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } else {
            showSnackbar('Error updating poll', 'Please try again', 'error');
        }

        return response.data;
    } catch (error) {
        showSnackbar('Error updating poll', 'Please try again', 'error');
    }
};

export const GetLatestPoll = async (showSnackbar: showSnackbar) => {
    try {
        const response = await api.get('latestPoll');
        return response.data;
    } catch (error) {
        showSnackbar(
            'Error fetching latest poll',
            'Please refresh the page',
            'error'
        );
    }
};

interface SubmitVoteProps {
    optionId: number;
    pollId: number;
    setPoll: React.Dispatch<React.SetStateAction<Poll>>;
    setVotedOptionId: React.Dispatch<React.SetStateAction<number>>;
    showSnackbar: showSnackbar;
}
export const SubmitVote = async ({
    optionId,
    pollId,
    setPoll,
    setVotedOptionId,
    showSnackbar
}: SubmitVoteProps) => {
    try {
        const result = await api.post('vote', { optionId, pollId });

        if (result.status === 200) {
            showSnackbar('Vote submitted', '', 'success');
            const updatedPoll = result.data;
            setPoll(updatedPoll);
            setVotedOptionId(optionId);
        } else {
            showSnackbar('Error submitting vote', 'Please try again', 'error');
        }

        return result.data;
    } catch (error) {
        showSnackbar('Error submitting vote', 'Please try again', 'error');
    }
};

interface DeletePollProps {
    pollId: number;
    showSnackbar: showSnackbar;
}
export const DeletePoll = async ({ pollId, showSnackbar }: DeletePollProps) => {
    try {
        const result = await api.delete(`deletePoll/${pollId}`);

        if (result.status === 200) {
            showSnackbar(
                'Poll deleted',
                'The page will now refresh',
                'success'
            );
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } else {
            showSnackbar('Error deleting poll', 'Please try again', 'error');
        }

        return result.data;
    } catch (error) {
        showSnackbar('Error deleting poll', 'Please try again', 'error');
    }
};
