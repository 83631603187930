// LOGIC
import { Dispatch, SetStateAction } from 'react';
import api from '../../../../api';
import { Creditor } from '../interfaces/GeneralInterface';
import { showSnackbar } from '../interfaces/GlobalInterface';
import { HondaInvoicePartLine } from '../interfaces/PartsInterface';

interface SelectedPart {
    unitData: { SupplierIds?: { SupplierId?: string }[]; partNumber?: string };
    stockData: {};
}
interface HandleAddPartInterface {
    (
        newPart: {
            partNumber: string;
            name: string;
            supplier: Creditor;
            costPriceDaily: number;
            priceRRP: number;
            SupplierIds?: {
                id: number;
                SupplierId: number;
                code: string;
                name: string;
                SiteId: number;
            }[];
        },
        setErrors: Dispatch<
            SetStateAction<{
                partNumber: boolean;
                name: boolean;
                supplier: boolean;
                costPrice: boolean;
                rrp: boolean;
            }>
        >,
        showSnackbar: showSnackbar,
        setButtonLoading: Dispatch<SetStateAction<boolean>>,
        setSelectedPart?: Dispatch<SetStateAction<SelectedPart>>,
        setDialogOpen?: Dispatch<SetStateAction<boolean>>,
        partList?: HondaInvoicePartLine[],
        setPartList?: Dispatch<SetStateAction<HondaInvoicePartLine[]>>
    ): void;
}

const HandleAddPart: HandleAddPartInterface = (
    newPart,
    setErrors,
    showSnackbar,
    setButtonLoading,
    setSelectedPart,
    setDialogOpen,
    partList,
    setPartList
) => {
    let isError = false;
    const errors = {
        partNumber: false,
        name: false,
        supplier: false,
        costPrice: false,
        rrp: false
    };

    if (newPart.partNumber == null || newPart.partNumber === '') {
        isError = true;
        errors.partNumber = true;
    }
    if (newPart.name == null || newPart.name === '') {
        isError = true;
        errors.name = true;
    }
    if (newPart.supplier == null || !newPart.supplier?.id) {
        isError = true;
        errors.supplier = true;
    }
    if (
        newPart.costPriceDaily == null ||
        newPart.costPriceDaily <= 0 ||
        isNaN(newPart.costPriceDaily)
    ) {
        isError = true;
        errors.costPrice = true;
    }
    if (
        newPart.priceRRP == null ||
        newPart.priceRRP <= 0 ||
        isNaN(newPart.priceRRP)
    ) {
        isError = true;
        errors.rrp = true;
    }

    setErrors(errors);

    if (!isError) {
        setButtonLoading(true);
        let supplierObj = [
            {
                id: 1,
                SupplierId: newPart.supplier.id,
                code: newPart.supplier.code,
                name: newPart.supplier.name,
                SiteId: parseInt(localStorage.getItem('SiteId'))
            }
        ];
        newPart.SupplierIds = supplierObj;

        api.post(
            `addNewPart?SiteId=${localStorage.getItem('SiteId')}`,
            newPart
        ).then((res) => {
            if (res.status === 200) {
                if (setSelectedPart) {
                    setSelectedPart(res.data);
                }
                // Used in stock invoice to update the list of non existing part as we add them
                if (partList && setPartList) {
                    let newPartList = JSON.parse(JSON.stringify(partList));
                    for (let part of newPartList) {
                        if (part.PartNumber === newPart.partNumber) {
                            part.created = true;
                        }
                    }
                    setPartList(newPartList);
                }
                if (setDialogOpen) {
                    showSnackbar('Part successfully added.');
                    setDialogOpen(false);
                }
            } else if (res.status === 400) {
                showSnackbar(
                    'Part already exist.',

                    'Make sure to use a non-existing part number and supplier combination.',
                    'warning'
                );
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
            setButtonLoading(false);
        });
    } else {
        showSnackbar(
            'Whoops! Looks like you are missing some data.',
            'Please fill out the highlighted fields to continue.',
            'error'
        );
        setButtonLoading(false);
    }
};

export default HandleAddPart;
