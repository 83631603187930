// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import CalculatePartTotal from './CalculatePartTotal';
import CalculateMiscTotal from './CalculateMiscTotal';
// INTERFACES
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';

const GetAddedCostsTotals = (
    vehicle: Vehicle,
    vehicleAddedCosts,
    setAddedCostTotals: Dispatch<
        SetStateAction<{ services: number; sales: number; invoices: number }>
    >
) => {
    let serviceTotal = 0;
    let salesTotal = 0;
    let invoicesTotal = 0;
    if (vehicle != null) {
        vehicleAddedCosts.services.forEach((service) => {
            serviceTotal += service.labourCost;
            serviceTotal += CalculatePartTotal(service);
            serviceTotal += CalculateMiscTotal(service);
        });

        vehicleAddedCosts.sales.forEach((sale) => {
            sale.orderLines.forEach((line) => {
                salesTotal += parseFloat(line.totalPrice);
            });
        });

        vehicleAddedCosts.invoices.forEach((invoice) => {
            // Some invoices don't have any orderlines (legacy) so we just the documentTotal for these
            if (invoice.StockInvoice?.orderLines?.length > 0) {
                const sumOfAmounts = invoice.StockInvoice.orderLines
                    .filter(
                        (line) => line.Vehicle && line.Vehicle.id === vehicle.id
                    )
                    .reduce((sum, line) => sum + parseFloat(line.amount), 0);

                if (
                    invoice.StockInvoice.gstType === 'No GST' ||
                    invoice.StockInvoice.gstType === 'N/T'
                ) {
                    invoicesTotal += parseFloat(sumOfAmounts);
                } else {
                    invoicesTotal += parseFloat(sumOfAmounts) / 1.1;
                }
            } else {
                invoicesTotal += parseFloat(invoice.StockInvoice.documentTotal);
            }
        });
    }
    setAddedCostTotals({
        services: serviceTotal,
        sales: salesTotal,
        invoices: invoicesTotal
    });
};

export default GetAddedCostsTotals;
