// INTERFACES
import {
    Credit,
    Order,
    Voucher
} from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
// LOGIC
import api from '../../../../../api';
import { ToFixed } from '../../../global/logic/Formatters';
import PDFVoucherInvoice from '../../voucher/logic/PDFVoucherInvoice';
import CreditPDF from './CreditPDF';

interface TempOrder extends Order {
    orderLines?: {
        rowId?: number;
        UnitId?: number;
        name?: string;
        partNumber?: string;
        priceRRP?: number;
        priceCharged?: string;
        pricePaid?: string;
        costPriceDaily?: number;
        costPriceAverage?: number;
        quantity?: string;
        backorderQuantity?: string;
        incGst?: boolean;
        totalPrice?: number;
        gstFree?: boolean;
        totalCost?: number;
        BinLocationIds?: { name: string }[];
        SOH?: number;
        RES?: number;
        tradeRRP?: number;
        isTradePrice?: boolean;
        margin?: number;
        stockOrderType?: boolean;
        collecting?: boolean;
        inactive?: boolean;
        linkedSaleId?: number;
        returnedQuantity?: number; // used in crediting order
        returnedBackorderQuantity?: number; // used in crediting order
        dateRefunded?: string; // used in crediting order
        creditId?: number; // used in crediting order
        refundAmount?: string; // used in crediting order
        amountRefunded?: number; // used in crediting order
        voucherCode?: string;
    }[];
}

interface CreditProps {
    saleDetails: TempOrder;
    creditDetails: Credit;
    freightRefunding: string;
    vouchers: { voucher: Voucher; qtyReturning: number }[];
    returnReason: string;
    returnMethod: string;
    selectedTill: number;
    showSnackbar: showSnackbar;
}

/**
 * HandleUpdateCredit
 * Submit the credit to the backend
 * @author Pierre
 * @params CreditProps
 */
const HandleUpdateCredit = async ({
    saleDetails,
    creditDetails,
    returnReason,
    returnMethod,
    selectedTill,
    freightRefunding,
    vouchers,
    showSnackbar
}: CreditProps) => {
    let totalRefund =
        saleDetails.orderLines
            .map((x) =>
                !x.voucherCode ? parseFloat(x.refundAmount ?? '0') : 0
            )
            .reduce((acc, value) => acc + value, 0) +
        (parseFloat(freightRefunding) ?? 0) +
        vouchers
            .map((x) => (x.qtyReturning === 1 ? x.voucher.totalPrice : 0))
            .reduce((acc, value) => acc + value, 0);

    // CHECK THAT THE NEW FREIGHT REFUNDING IS NOT LESS THAN THE FREIGHT REFUNDED ALREADY
    if (parseFloat(freightRefunding) < saleDetails.freightRefunded) {
        showSnackbar(
            'Cannot refund less freight than was refunded on previous credit',
            '',
            'warning'
        );
        return;
    }

    await api
        .put('/creditNextGen', {
            OrderId: saleDetails?.id,
            referenceType: creditDetails.referenceType,
            referenceId: creditDetails.id,
            SiteId: localStorage.getItem('SiteId'),
            TillId: selectedTill,
            orderLines: saleDetails.orderLines,
            amountRefunded: ToFixed(totalRefund),
            vouchers: vouchers,
            freightRefunded: ToFixed(parseFloat(freightRefunding)),
            reasonForCredit: returnReason,
            refundMethod: returnMethod,
            CustomerId: saleDetails.CustomerId,
            saleStatus: saleDetails.status
        })
        .then(async (res) => {
            if (res.status === 200) {
                showSnackbar('Credit processed successfully.');

                // If refunding to a voucher, print the voucher PDF
                if (returnMethod === 'voucher') {
                    await PDFVoucherInvoice(res.data.voucher.id);
                }

                // Print the credit PDF
                await CreditPDF({
                    siteId: parseInt(localStorage.getItem('SiteId')),
                    saleDetails: saleDetails,
                    customerDetails: saleDetails.Customer,
                    vouchers: res.data.creditedVouchers,
                    allCredits: false,
                    credit: res.data.credit
                });

                //                 window.location.href = '/inventory/sales';
            } else {
                showSnackbar(
                    'Something went wrong, the refund has not been updated',
                    'Please check your data and try again, or contact the ORA Development Team',
                    'error'
                );
            }
        });
};

export default HandleUpdateCredit;
