// IMPORTS
import { TextField, Typography, InputAdornment, useTheme } from '@mui/material';
import MuiAutocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';

interface AutocompleteProps {
    size?: string; // Size of the textfield, defaults to normal
    options: any[]; // JSON array containing the options for the dropdown
    useTwoOptionLabels: boolean; // The number of option labels to display (1, 2 or 3)
    primaryOptionLabel: string; // The primary option label is the larger black font
    secondaryOptionLabel?: string; // The secondary option label is the smaller grey font to the left of the primary option
    textfieldLabel: string; // Label that displays on the textfield component
    textfieldVariant?: any; // Outlined or Standard
    isError?: boolean; // Textfield error prop
    helperText?: string; // Textfield helperText prop
    placeholder?: string; // Textfield placeholder props
    isOpenOnInput?: boolean;
    isRequired?: boolean; // Is a value required for submission?
    isDisabled?: boolean; // Is the component disabled?
    selectedValue: any;
    includeBar?: boolean; // If using two option label values to have them seperated by a bar (if not just uses space)
    inputValue?: string; // This is only used when we want to start being able to add items through this field (currently does not handle this)
    canSearch?: boolean; // Tells the component whether there is a function to handle searching for the input value
    useNestedObject?: boolean; // Tells the component whether the secondary option label is a nested object or not
    secondaryOptionLabelNest?: string; // The key in the nested object
    handleSearchValue?: () => any; // Function to handle searching for the input values
    handleSelectedValueChange: (newValue: any, event: any) => any;
    handleInputValueChange?: (newInputValue: any, event: any) => any;
    includeTextfieldIcon?: boolean;
    textfieldIcon?;
    cypressLabel?: string;
}

const Autocomplete = ({
    textfieldIcon,
    includeTextfieldIcon,
    size,
    options,
    useNestedObject,
    useTwoOptionLabels,
    primaryOptionLabel,
    secondaryOptionLabel,
    canSearch,
    handleSearchValue,
    selectedValue,
    secondaryOptionLabelNest,
    handleInputValueChange,
    includeBar,
    handleSelectedValueChange,
    inputValue,
    textfieldLabel,
    textfieldVariant = 'outlined',
    isError,
    helperText,
    placeholder,
    isOpenOnInput,
    isRequired,
    isDisabled,
    cypressLabel
}: AutocompleteProps) => {
    const currentMode = useTheme().palette.mode;

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (inputValue?.length > 0) {
            setOpen(true);
        }
    }, [inputValue]);

    const handleOpen = () => {
        if (inputValue.length > 0) {
            setOpen(true);
        }
    };

    return (
        <MuiAutocomplete
            {...(isOpenOnInput && {
                open: open,
                onOpen: handleOpen,
                onClose: () => setOpen(false)
            })}
            ListboxProps={{
                hidden: canSearch
                    ? inputValue.length > 0
                        ? false
                        : true
                    : false
            }}
            data-cy={cypressLabel ? cypressLabel : ''}
            disabled={isDisabled}
            options={options || []}
            value={selectedValue}
            inputValue={inputValue}
            onChange={(event, newValue) => {
                handleSelectedValueChange(newValue, event);
            }}
            onInputChange={(event, newInputValue) =>
                handleInputValueChange(newInputValue, event)
            }
            getOptionLabel={(option) =>
                useTwoOptionLabels
                    ? useNestedObject
                        ? (option[String(primaryOptionLabel)] +
                          ' | ' +
                          option[secondaryOptionLabel]
                              ? option[secondaryOptionLabel][
                                    secondaryOptionLabelNest
                                ]
                              : '') || ''
                        : option[String(primaryOptionLabel)] +
                              ' | ' +
                              option[secondaryOptionLabel] || ''
                    : option[String(primaryOptionLabel)] || ''
            }
            renderOption={(props, option) => {
                return useTwoOptionLabels ? (
                    <li key={option?.id}>
                        <Typography
                            {...props}
                            sx={{ display: 'flex' }}
                            data-cy={
                                cypressLabel
                                    ? `${cypressLabel}-${option?.id}`
                                    : ''
                            }
                        >
                            <span>{option[String(primaryOptionLabel)]}</span>
                            &nbsp;|&nbsp;
                            <span
                                style={{
                                    color:
                                        currentMode === 'dark'
                                            ? '#C0BBBB'
                                            : '#505050',
                                    fontSize: '14px'
                                }}
                            >
                                {option[secondaryOptionLabel]}
                            </span>
                        </Typography>
                    </li>
                ) : (
                    <li key={option?.id}>
                        <Typography
                            {...props}
                            sx={{ display: 'flex' }}
                            data-cy={
                                cypressLabel
                                    ? `${cypressLabel}-${option?.id}`
                                    : ''
                            }
                        >
                            <span>{option[String(primaryOptionLabel)]}</span>
                        </Typography>
                    </li>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size={size === 'small' ? 'small' : 'medium'}
                    label={textfieldLabel}
                    InputLabelProps={{ shrink: true }}
                    variant={textfieldVariant}
                    error={isError}
                    disabled={isDisabled}
                    helperText={isError ? helperText : null}
                    placeholder={placeholder}
                    required={isRequired}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && canSearch) handleSearchValue();
                    }}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        startAdornment: includeTextfieldIcon && (
                            <InputAdornment position="start">
                                {textfieldIcon}
                            </InputAdornment>
                        )
                    }}
                />
            )}
        />
    );
};

export default Autocomplete;
