import { Button, Grid, Typography } from '@mui/material';
import Paper from '../../../global/Paper';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import { Dispatch, SetStateAction } from 'react';

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}

interface GroupedOnlineOrders {
    onHold: GroupedOnlineOrder[];
    onBackorder: GroupedOnlineOrder[];
    newOrders: GroupedOnlineOrder[];
    pendingDispatch: GroupedOnlineOrder[];
}

interface CountersProps {
    groupedOrders: GroupedOnlineOrders;
    setSelectedGroupedOrder: Dispatch<SetStateAction<GroupedOnlineOrder[]>>;
    groupedTitle: string;
    setGroupedTitle: Dispatch<SetStateAction<String>>;
}

const Counters = ({
    groupedOrders,
    setSelectedGroupedOrder,
    groupedTitle,
    setGroupedTitle
}: CountersProps) => {
    const currentSite = Number(localStorage.getItem('SiteId'));

    const { newOrders, onHold, onBackorder, pendingDispatch } = groupedOrders;
    const currentSiteOnBackorder = onBackorder.filter(
        (order) => order.oraOrder.SiteId === currentSite
    );

    const currentSitePendingDispatch = pendingDispatch.filter(
        (order) => order.oraOrder.SiteId === currentSite
    );

    const currentSiteOnHold = onHold.filter(
        (order) => order.oraOrder.SiteId === currentSite
    );

    const handleButtonClick = (title: string, array: GroupedOnlineOrder[]) => {
        setSelectedGroupedOrder(array);
        setGroupedTitle(title);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3} textAlign="center">
                    <Paper>
                        <Typography variant="h6">New Orders</Typography>
                        <Typography variant="h5">{newOrders.length}</Typography>
                        <Button
                            disabled={
                                groupedTitle === 'New Orders' ? true : false
                            }
                            onClick={() =>
                                handleButtonClick('New Orders', newOrders)
                            }
                        >
                            View Orders
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={3} textAlign="center">
                    <Paper>
                        <Typography variant="h6">On Hold</Typography>
                        <Typography variant="h5">
                            {groupedTitle === 'Current On Hold'
                                ? currentSiteOnHold.length
                                : onHold.length}
                        </Typography>
                        <Button
                            disabled={groupedTitle === 'On Hold' ? true : false}
                            onClick={() => handleButtonClick('On Hold', onHold)}
                        >
                            View All
                        </Button>
                        <Button
                            disabled={
                                groupedTitle === 'Current On Hold'
                                    ? true
                                    : false
                            }
                            onClick={() =>
                                handleButtonClick(
                                    'Current On Hold',
                                    currentSiteOnHold
                                )
                            }
                        >
                            Current Site
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={3} textAlign="center">
                    <Paper>
                        <Typography variant="h6">On Backorder</Typography>
                        <Typography variant="h5">
                            {groupedTitle === 'Current On Backorder'
                                ? currentSiteOnBackorder.length
                                : onBackorder.length}
                        </Typography>
                        <Button
                            disabled={
                                groupedTitle === 'All On Backorder'
                                    ? true
                                    : false
                            }
                            onClick={() =>
                                handleButtonClick(
                                    'All On Backorder',
                                    onBackorder
                                )
                            }
                        >
                            View All
                        </Button>
                        <Button
                            disabled={
                                groupedTitle === 'Current On Backorder'
                                    ? true
                                    : false
                            }
                            onClick={() =>
                                handleButtonClick(
                                    'Current On Backorder',
                                    currentSiteOnBackorder
                                )
                            }
                        >
                            Current Site
                        </Button>
                    </Paper>
                </Grid>

                <Grid item xs={3} textAlign="center">
                    <Paper>
                        <Typography variant="h6">Pending Dispatch</Typography>
                        <Typography variant="h5">
                            {groupedTitle === 'Current Pending Dispatch'
                                ? currentSitePendingDispatch.length
                                : pendingDispatch.length}
                        </Typography>
                        <Button
                            disabled={
                                groupedTitle === 'Pending Dispatch'
                                    ? true
                                    : false
                            }
                            onClick={() =>
                                handleButtonClick(
                                    'Pending Dispatch',
                                    pendingDispatch
                                )
                            }
                        >
                            View All
                        </Button>
                        <Button
                            disabled={
                                groupedTitle === 'Current Pending Dispatch'
                                    ? true
                                    : false
                            }
                            onClick={() =>
                                handleButtonClick(
                                    'Current Pending Dispatch',
                                    currentSitePendingDispatch
                                )
                            }
                        >
                            Current Site
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default Counters;
