import React, { useEffect, useState } from 'react';
import {
    Typography,
    Button,
    LinearProgress,
    Grid,
    Tooltip,
    Chip,
    Menu,
    MenuItem
} from '@mui/material';
import { GetLatestPoll, SubmitVote } from '../logic/HandlePoll';
import { User } from '../../../global/interfaces/GeneralInterface';
import Paper from '../../../global/Paper';
import { IconButton } from '@material-ui/core';
import { Add, MoreVert } from '@mui/icons-material';
import NewPollDialog from './NewPollDialog';
import { withSnackbar } from '../../../global/WrappingSnackbar';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { DateSafeFormatter } from '../../../global/logic/Formatters';
import DeletePollDialog from './DeletePollDialog';
import { Poll } from '../../../global/interfaces/PollInterface';

interface PollProps {
    currentUser: User;
    showSnackbar: showSnackbar;
}

const PollContent = ({ currentUser, showSnackbar }: PollProps) => {
    const [poll, setPoll] = useState<Poll | null>(null);
    const [votedOptionId, setVotedOptionId] = useState<number | null>(null);
    const [totalVotes, setTotalVotes] = useState(0);
    const [openNewPollDialog, setOpenNewPollDialog] = useState(false);
    const [openEditPollDialog, setOpenEditPollDialog] = useState(false);
    const [openDeletePollDialog, setOpenDeletePollDialog] = useState(false);
    const [showVotesForOptionId, setShowVotesForOptionId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const fetachPoll = async () => {
            const result = await GetLatestPoll(showSnackbar);
            if (result.id) {
                // check if current user has voted
                const myVotedOption = result.PollOptions.find((option) => {
                    return option.Votes.some(
                        (vote) => vote.UserId === currentUser.id
                    );
                });

                if (myVotedOption) {
                    setVotedOptionId(myVotedOption.id);
                }

                setPoll(result);
                setTotalVotes(
                    result.PollOptions.reduce(
                        (acc, option) => acc + option.Votes.length,
                        0
                    )
                );
            }
        };

        fetachPoll();
    }, [currentUser.id]);

    const handleVote = async (optionId) => {
        // if user has already voted, do nothing
        if (votedOptionId) {
            return;
        }

        await SubmitVote({
            optionId,
            pollId: poll.id,
            setPoll,
            setVotedOptionId,
            showSnackbar
        });
    };

    const getVotePercentage = (voteCount) => {
        return totalVotes > 0 ? ((voteCount / totalVotes) * 100).toFixed(2) : 0;
    };

    const handleViewDetails = (optionId) => {
        if (showVotesForOptionId === optionId) {
            setShowVotesForOptionId(null);
        } else {
            setShowVotesForOptionId(optionId);
        }
    };

    if (!poll && !currentUser.isGod) {
        return null;
    }

    return (
        <Paper textAlign={!poll ? 'center' : undefined}>
            {!poll && currentUser.isGod && (
                <>
                    <Typography variant="h5">Create New Poll</Typography>
                    <Tooltip title="Create new poll">
                        <IconButton
                            onClick={() => {
                                setOpenNewPollDialog(true);
                            }}
                            color="inherit"
                        >
                            <Add fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </>
            )}

            {poll && (
                <Grid container spacing={1}>
                    <Grid item xs={12} container justifyContent="space-between">
                        <Grid item xs={10}>
                            <Typography variant="h5">
                                {poll.question}
                            </Typography>
                            <Typography variant="caption">
                                Created by {poll.User.firstName}{' '}
                                {poll.User.lastName} at{' '}
                                {DateSafeFormatter(poll.createdAt)}
                            </Typography>
                        </Grid>

                        {currentUser.isGod && (
                            <>
                                <IconButton
                                    onClick={handleClickMenu}
                                    color="inherit"
                                >
                                    <MoreVert />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={handleCloseMenu}
                                >
                                    <MenuItem
                                        onClick={() =>
                                            setOpenEditPollDialog(true)
                                        }
                                    >
                                        Edit Poll
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            setOpenDeletePollDialog(true)
                                        }
                                    >
                                        Delete Poll
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            setOpenNewPollDialog(true)
                                        }
                                    >
                                        Create New Poll
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </Grid>

                    {poll.PollOptions.map((option) => (
                        <Grid
                            item
                            xs={12}
                            key={option.id}
                            container
                            spacing={1}
                        >
                            <Grid item xs={8}>
                                <Button
                                    fullWidth
                                    disabled={!!votedOptionId}
                                    onClick={() => handleVote(option.id)}
                                    style={{
                                        justifyContent: 'flex-start',
                                        textAlign: 'left',
                                        color: 'inherit'
                                    }}
                                >
                                    {option.content}{' '}
                                    {votedOptionId === option.id && (
                                        <Chip
                                            label="My Vote"
                                            size="small"
                                            color="primary"
                                            style={{ marginLeft: '1em' }}
                                        />
                                    )}
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Tooltip
                                    title="Click to view details"
                                    placement="top-end"
                                >
                                    <Typography
                                        variant="body2"
                                        align="right"
                                        onClick={() =>
                                            handleViewDetails(option.id)
                                        }
                                    >
                                        {option.Votes.length} votes
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <LinearProgress
                                    variant="determinate"
                                    value={Number(
                                        getVotePercentage(option.Votes.length)
                                    )}
                                />
                            </Grid>
                            {showVotesForOptionId === option.id &&
                                option.Votes.length > 0 && (
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                        >
                                            {option.Votes.map(
                                                (vote) =>
                                                    `${vote.User.firstName} ${vote.User.lastName}`
                                            ).join(', ')}{' '}
                                            voted for this option
                                        </Typography>
                                    </Grid>
                                )}
                        </Grid>
                    ))}
                </Grid>
            )}

            <NewPollDialog
                open={openNewPollDialog || openEditPollDialog}
                onClose={() => {
                    setOpenNewPollDialog(false);
                    setOpenEditPollDialog(false);
                }}
                showSnackbar={showSnackbar}
                poll={openEditPollDialog ? poll : null}
            />

            <DeletePollDialog
                open={openDeletePollDialog}
                onClose={() => setOpenDeletePollDialog(false)}
                pollId={poll ? poll.id : null}
                showSnackbar={showSnackbar}
            />
        </Paper>
    );
};

export default withSnackbar(PollContent);
