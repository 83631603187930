// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// INTERFACES
import { Customer } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
// LOGIC
import api from '../../../../../api';
import ValidateCustomer from './ValidateCustomer';

interface UpdateProps {
    customerInfo: Customer;
    showSnackbar: showSnackbar;
    updateDebtor?: boolean;
    newDebtorStatus?: boolean;
    refresh?: boolean;
    setReadOnly?: Dispatch<SetStateAction<boolean>>;
    resultsList?;
    setResultsList?;
    setSelectedEntity?;
    setCustomerDrawerOpen?: Dispatch<SetStateAction<boolean>>;
    setLoading?: Dispatch<SetStateAction<boolean>>;
}

/**
 * HandleUpdateCustomer
 * Update the database with the customer's new details
 * @author Estienne
 * @param UpdateProps
 */
const HandleUpdateCustomer = ({
    customerInfo,
    showSnackbar,
    updateDebtor,
    newDebtorStatus,
    refresh,
    setReadOnly,
    resultsList,
    setResultsList,
    setSelectedEntity,
    setCustomerDrawerOpen,
    setLoading
}: UpdateProps) => {
    // If updating just the debtor status, just send the data straight to the backend
    if (updateDebtor) {
        api.put(`customer/${customerInfo.id}`, {
            debtor: newDebtorStatus,
            accountLimit: newDebtorStatus ? 0 : null,
            paymentTerms: newDebtorStatus ? 30 : null
        }).then((res) => {
            if (res.status === 200) {
                showSnackbar(
                    newDebtorStatus
                        ? 'Customer is now a debtor with an account limit of $0.'
                        : 'Customer is no longer a debtor.'
                );
                window.location.reload();
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        });
    }

    // Otheriwse we need to check the customer data and validate it prior to sending it to the backend
    else {
        let verified = ValidateCustomer(customerInfo);
        if (verified.verified === false) {
            showSnackbar(verified.message, verified.subMessage, 'error');
        } else if (customerInfo.id) {
            if (setLoading) {
                setLoading(true);
            }
            api.put(`customer/${customerInfo.id}`, customerInfo).then((res) => {
                if (res.status === 200) {
                    showSnackbar('Customer updated successfully.');
                    if (setSelectedEntity) {
                        setSelectedEntity(customerInfo);
                    }

                    if (refresh) {
                        window.location.reload();
                    } else {
                        setReadOnly(true);
                        if (resultsList && setResultsList) {
                            let newResultList = JSON.parse(
                                JSON.stringify(resultsList)
                            );
                            let index = newResultList.findIndex(
                                (x) => x.id === customerInfo.id
                            );
                            newResultList[index] = customerInfo;
                            setResultsList(newResultList);
                        }
                    }
                } else {
                    showSnackbar(
                        'Whoops! Something went wrong on our end.',
                        'Please contact your IT department.',
                        'error'
                    );
                }
            });
        } else {
            if (setLoading) {
                setLoading(true);
            }
            // Add the customer if they're new
            api.post(`customer`, customerInfo).then((res) => {
                if (res.status === 200) {
                    showSnackbar('Customer added successfully.');
                    if (setResultsList) {
                        setResultsList([res.data]);
                    }

                    if (setSelectedEntity) {
                        setSelectedEntity(res.data);
                    }

                    if (setCustomerDrawerOpen) {
                        setCustomerDrawerOpen(false);
                    }

                    if (refresh) {
                        window.location.href = `/viewCustomer/${res.data.id}`;
                    }

                    if (setLoading) {
                        setLoading(false);
                    }
                } else {
                    showSnackbar(
                        'Whoops! Something went wrong on our end.',
                        'Please contact your IT department.',
                        'error'
                    );
                }
            });
        }
    }
};

export default HandleUpdateCustomer;
