// IMPORTS
import { Add, Check, DirectionsCar } from '@mui/icons-material';
import { TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
// COMPONENTS
import DataCell from '../../global/tableComponents/DataCell';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataTable from '../../global/tableComponents/DataTable';
import Drawer from '../../global/Drawer';
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import TableSearch from '../../global/tableComponents/TableSearch';
import ManufacturerVehicleFilter from './components/ManufacturerVehicleFilter';
import ManufacturerVehicleDrawerContent from './components/ManufacturerVehicleDrawerContent';
// LOGIC
import ConvertArrayToFilter from '../../global/logic/ConvertArrayToFilter';
import { CurrencyFormatter } from '../../global/logic/Formatters';
// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { ManufacturerVehicle } from '../../global/interfaces/VehicleSaleInterface';
import dayjs from 'dayjs';

const clearedFilter = {
    manufacturerVehicleFilter: {
        make: [],
        model: [],
        series: [],
        yearStart: null,
        yearEnd: null,
        learnerApproved: null
    }
};
const clearedSort = ['id', 'DESC'];

/**
 * Manufacturer Vehicle Table
 * @author Estienne
 * @returns {JSX} a PageWrapper component containing the
 * name, model year, price (RRP) and LAMS status of
 * all Manufacturer Vehicles
 */
const ManufacturerVehicleTable = () => {
    const history = useHistory();
    const [resultsList, setResultsList] = useState<ManufacturerVehicle[]>([]);
    const [sort, setSort] = useState(clearedSort);
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(JSON.stringify(clearedFilter))
    );
    const [vehicleDrawerOpen, setVehicleDrawerOpen] = useState<boolean>(false);
    const [selectedManufacturerVehicle, setSelectedManufacturerVehicle] =
        useState<ManufacturerVehicle | null>(null);

    const actions = [
        {
            icon: <Add />,
            name: 'Add Manufacturer Vehicle',
            onClick: () => {
                setVehicleDrawerOpen(true);
                setSelectedManufacturerVehicle(null);
            }
        },
        {
            icon: <DirectionsCar />,
            name: 'View Vehicles',
            onClick: () => history.push('/vehicles/stock')
        }
    ];

    const columns = [
        { id: 0, label: 'ID', width: 100, name: 'id', sort: true },
        { id: 1, label: 'Make / Model / Series', width: 300 },
        { id: 2, label: 'Year Model', width: 100, name: 'year', sort: true },
        { id: 3, label: 'RRP', width: 100, name: 'priceRRP', sort: true },
        { id: 4, label: 'Learner Approved', width: 100 }
    ];

    const handleRequestCreate = () => {
        let make =
            filter.manufacturerVehicleFilter.make.length > 0
                ? ConvertArrayToFilter({
                      array: filter.manufacturerVehicleFilter.make,
                      filterStart: '&make=',
                      selector: 'id',
                      useId: true
                  })
                : '';
        let model =
            filter.manufacturerVehicleFilter.model.length > 0
                ? ConvertArrayToFilter({
                      array: filter.manufacturerVehicleFilter.model,
                      filterStart: '&model=',
                      selector: 'id',
                      useId: true
                  })
                : '';
        let series =
            filter.manufacturerVehicleFilter.series.length > 0
                ? ConvertArrayToFilter({
                      array: filter.manufacturerVehicleFilter.series,
                      filterStart: '&series=',
                      selector: 'id',
                      useId: true
                  })
                : '';

        // Year needs to be converted to 'yyyy' format
        // before being added to the apiString
        let yearStart =
            filter.manufacturerVehicleFilter.yearStart != null &&
            filter.manufacturerVehicleFilter.yearStart.isValid()
                ? '&yearStart=' +
                  filter.manufacturerVehicleFilter.yearStart.year().toString()
                : '';
        let yearEnd =
            filter.manufacturerVehicleFilter.yearEnd != null &&
            filter.manufacturerVehicleFilter.yearEnd.isValid()
                ? '&yearEnd=' +
                  filter.manufacturerVehicleFilter.yearEnd.year().toString()
                : '';
        let learnerApproved =
            filter.manufacturerVehicleFilter.learnerApproved != null
                ? '&learnerApproved=' +
                  filter.manufacturerVehicleFilter.learnerApproved
                : '';

        let apiString = `${make}${model}${series}${yearStart}${yearEnd}${learnerApproved}`;
        return apiString;
    };

    const isFilterActive = () => {
        let isActive = false;

        if (
            filter.manufacturerVehicleFilter.make.length > 0 ||
            filter.manufacturerVehicleFilter.model.length > 0 ||
            filter.manufacturerVehicleFilter.series.length > 0 ||
            filter.manufacturerVehicleFilter.yearStart != null ||
            filter.manufacturerVehicleFilter.yearEnd != null ||
            filter.manufacturerVehicleFilter.learnerApproved != null
        ) {
            isActive = true;
        }

        return isActive;
    };

    const getVehicleString = (row: ManufacturerVehicle) => {
        let vehicleString = '';

        if (row?.year) {
            const year = dayjs(row?.year).year();
            vehicleString += `${year} `;
        }
        vehicleString += `${row?.Make?.name} ${row?.Model?.name}`;
        if (row?.SeriesId) {
            vehicleString += ` ${row?.Series?.name}`;
        }
        return vehicleString;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Manufacturer Vehicles</Typography>
            <br />
            <TableSearch
                searchTitle="Search Vehicles"
                showFilter={true}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="manufacturerVehicleSearch"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
            >
                <ManufacturerVehicleFilter
                    filter={filter}
                    setFilter={setFilter}
                    isFilterActive={isFilterActive}
                />
            </TableSearch>

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={true}
                    actionsList={actions}
                >
                    {resultsList?.map((row) => (
                        <TableRow key={row.id}>
                            <DataCellColoured
                                handleClick={() => {
                                    setVehicleDrawerOpen(true);
                                    setSelectedManufacturerVehicle(row);
                                }}
                            >
                                {row.id}
                            </DataCellColoured>
                            <DataCell>{`${row.Make?.name} ${row.Model?.name} ${row.SeriesId ? row.Series.name : ''}`}</DataCell>
                            <DataCell>{row.year?.format('YYYY')}</DataCell>
                            <DataCell>
                                {CurrencyFormatter(row.priceRRP)}
                            </DataCell>
                            <DataCell>
                                {row.learnerApproved === true ? (
                                    <Check />
                                ) : null}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            <Drawer
                openDrawer={vehicleDrawerOpen}
                setOpenDrawer={setVehicleDrawerOpen}
                title={
                    selectedManufacturerVehicle?.id
                        ? getVehicleString(selectedManufacturerVehicle)
                        : 'New Manufacturer Vehicle'
                }
                subTitle={''}
            >
                <ManufacturerVehicleDrawerContent
                    selectedManufacturerVehicle={selectedManufacturerVehicle}
                    setSelectedManufacturerVehicle={
                        setSelectedManufacturerVehicle
                    }
                />
            </Drawer>
        </PageWrapper>
    );
};

export default ManufacturerVehicleTable;
