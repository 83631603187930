// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import {
    ManufacturerVehicle,
    Vehicle
} from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleUpdateManufacturerVehicle = (
    selectedManufacturerVehicle: ManufacturerVehicle,
    showSnackbar: showSnackbar,
    setReadOnly: Dispatch<SetStateAction<boolean>>,
    noRefresh?: boolean,
    setSelectedEntity?: Dispatch<SetStateAction<Vehicle>>
) => {
    api.put(
        `updateManufacturerVehicle/${selectedManufacturerVehicle.id}`,
        selectedManufacturerVehicle
    ).then((res) => {
        if (res.status === 200) {
            // Success
            showSnackbar('Manufacturer Vehicle Successfully Updated.');
            setReadOnly(true);

            if (setSelectedEntity) {
                setSelectedEntity(selectedManufacturerVehicle);
            }

            if (!noRefresh) {
                window.location.reload();
            }
        } else {
            // Misc Error
            showSnackbar(
                'Whoops, something seems to have gone wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default HandleUpdateManufacturerVehicle;
