import {
    useState,
    Dispatch,
    SetStateAction,
    useEffect,
    useContext
} from 'react';
import { Grid, TextField, MenuItem } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// COMPONENTS
import Autocomplete from '../../../global/Autocomplete';
import Fab from '../../../global/Fab';
import { withSnackbar } from '../../../global/WrappingSnackbar';
// LOGIC
import GetAllVehicleSpecifics from '../../../global/databaseLogic/GetAllVehicleSpecifics';
import GetAllModels from '../../../global/databaseLogic/GetAllModels';
import GetAllSeries from '../../../global/databaseLogic/GetAllSeries';
import HandleAddManufacturerVehicle from '../logic/HandleAddManufacturerVehicle';
import HandleUpdateManufacturerVehicle from '../logic/HandleUpdateManufacturerVehicle';
import {
    ValidateEmpty,
    ValidateYear
} from '../../../global/logic/vehicleValidation/ValidateVehicleFields';

// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Site, Specific } from '../../../global/interfaces/GeneralInterface';
import {
    ManufacturerVehicle,
    Vehicle
} from '../../../global/interfaces/VehicleSaleInterface';
import { SiteContext } from '../../../../main/Main';
import { Save, Edit } from '@mui/icons-material';
import dayjs from 'dayjs';

interface ManufacturerVehicleDrawerContentProps {
    selectedManufacturerVehicle: ManufacturerVehicle;
    setSelectedManufacturerVehicle: Dispatch<
        SetStateAction<ManufacturerVehicle>
    >;
    showSnackbar: showSnackbar;
    noRefresh?: boolean;
    setSelectedEntity?: Dispatch<SetStateAction<Vehicle>>;
    setVehicleDrawerOpen?: Dispatch<SetStateAction<boolean>>;
}

interface SpecificsList {
    makes?: Specific[];
    models?: Specific[];
    series?: Specific[];
    fuelTypes?: Specific[];
}

const ManufacturerVehicleDrawerContent = ({
    selectedManufacturerVehicle,
    setSelectedManufacturerVehicle,
    noRefresh,
    showSnackbar,
    setSelectedEntity,
    setVehicleDrawerOpen
}: ManufacturerVehicleDrawerContentProps) => {
    const siteDetails = useContext<Site>(SiteContext);

    const [readOnly, setReadOnly] = useState(false);
    const [newManufacturerVehicle, setNewManufacturerVehicle] = useState(false);
    const [models, setModels] = useState<Specific[]>([]);
    const [series, setSeries] = useState<Specific[]>([]);
    const [specifics, setSpecifics] = useState<SpecificsList>({
        makes: [],
        fuelTypes: []
    });

    useEffect(() => {
        // If adding a new Manufacturer vehicle
        if (!selectedManufacturerVehicle?.id) {
            setNewManufacturerVehicle(true);
        }
        // If selected a Manufacturer vehicle
        else {
            setReadOnly(true);
        }
        // Get all the initial values
        GetAllVehicleSpecifics(setSpecifics, ['makes', 'fuelTypes']);
        GetAllModels(setModels);
        GetAllSeries(setSeries);
    }, [selectedManufacturerVehicle?.id, newManufacturerVehicle]);

    // Update the models options when a make is selected
    useEffect(() => {
        GetAllModels(setModels, [], selectedManufacturerVehicle?.MakeId);
    }, [selectedManufacturerVehicle?.MakeId]);

    // Update the series options when a model is selected
    useEffect(() => {
        GetAllSeries(setSeries, [], selectedManufacturerVehicle?.ModelId);
    }, [selectedManufacturerVehicle?.ModelId]);

    /**
     * handleFabClick
     * Verify and handle the data entered into the drawer
     */
    const handleFabClick = () => {
        if (readOnly) {
            setReadOnly(false);
        } else if (newManufacturerVehicle) {
            let filledFields = ValidateEmpty([
                selectedManufacturerVehicle?.MakeId,
                selectedManufacturerVehicle?.ModelId,
                selectedManufacturerVehicle?.year
            ]);
            let validYear = ValidateYear(selectedManufacturerVehicle?.year);

            // Check that Make, Model and Year Model are filled
            if (!filledFields) {
                showSnackbar(
                    'Make sure all required fields are filled.',
                    'Make, Model and Year Model are required here.',
                    'error'
                );
            }

            // Check that Year Model is valid
            else if (validYear !== 'valid') {
                showSnackbar(
                    'Make sure a valid year is entered.',
                    validYear,
                    'error'
                );
            } else {
                HandleAddManufacturerVehicle(
                    selectedManufacturerVehicle,
                    showSnackbar,
                    noRefresh,
                    setReadOnly,
                    setSelectedEntity,
                    setVehicleDrawerOpen
                );
            }
        } else {
            HandleUpdateManufacturerVehicle(
                selectedManufacturerVehicle,
                showSnackbar,
                setReadOnly,
                noRefresh,
                setSelectedEntity
            );
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                {newManufacturerVehicle ? (
                    <>
                        <Grid item xs={6}>
                            {/* Makes Dropdown */}
                            <Autocomplete
                                options={specifics.makes}
                                useTwoOptionLabels={false}
                                primaryOptionLabel={'name'}
                                textfieldLabel="Make"
                                selectedValue={
                                    selectedManufacturerVehicle?.MakeId
                                }
                                handleSelectedValueChange={(event) => {
                                    setSelectedManufacturerVehicle({
                                        ...selectedManufacturerVehicle,
                                        MakeId: event?.id,
                                        Make: event
                                    });
                                }}
                                handleInputValueChange={() => null}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {/* Models Dropdown */}
                            <Autocomplete
                                options={models}
                                useTwoOptionLabels={false}
                                primaryOptionLabel={'name'}
                                textfieldLabel="Model"
                                selectedValue={
                                    selectedManufacturerVehicle?.ModelId
                                }
                                handleSelectedValueChange={(event) => {
                                    setSelectedManufacturerVehicle({
                                        ...selectedManufacturerVehicle,
                                        ModelId: event?.id,
                                        Model: event
                                    });
                                }}
                                handleInputValueChange={() => null}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {/* Series Dropdown */}
                            <Autocomplete
                                options={series}
                                useTwoOptionLabels={false}
                                primaryOptionLabel={'name'}
                                textfieldLabel="Series"
                                selectedValue={
                                    selectedManufacturerVehicle?.SeriesId
                                }
                                handleSelectedValueChange={(event) =>
                                    setSelectedManufacturerVehicle({
                                        ...selectedManufacturerVehicle,
                                        SeriesId: event?.id,
                                        Series: event
                                    })
                                }
                                handleInputValueChange={() => null}
                            />
                        </Grid>
                    </>
                ) : null}

                {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                    newManufacturerVehicle ? (
                        <Grid item xs={6}>
                            {/* Learner Approved Dropdown */}
                            <TextField
                                fullWidth
                                select
                                disabled={!newManufacturerVehicle}
                                label="Learner Approved"
                                value={
                                    selectedManufacturerVehicle?.learnerApproved
                                }
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) =>
                                    setSelectedManufacturerVehicle({
                                        ...selectedManufacturerVehicle,
                                        learnerApproved:
                                            e.target.value === 'Yes'
                                                ? true
                                                : false
                                    })
                                }
                            >
                                <MenuItem value={'Yes'}>Yes</MenuItem>
                                <MenuItem value={'No'}>No</MenuItem>
                            </TextField>
                        </Grid>
                    ) : (
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                disabled={!newManufacturerVehicle}
                                label="Learner Approved"
                                value={
                                    selectedManufacturerVehicle?.learnerApproved
                                        ? 'Yes'
                                        : 'No'
                                }
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    )
                ) : null}
                {newManufacturerVehicle ? (
                    <Grid item xs={6}>
                        {/* Year Model Field */}
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                disabled={readOnly}
                                format="YYYY"
                                views={['year']}
                                label="Year Model"
                                value={
                                    selectedManufacturerVehicle?.year
                                        ? dayjs(
                                              selectedManufacturerVehicle.year
                                          )
                                        : null
                                }
                                onChange={(newValue) => {
                                    setSelectedManufacturerVehicle({
                                        ...selectedManufacturerVehicle,
                                        year: newValue ? newValue : null
                                    });
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                ) : null}
                <Grid item xs={newManufacturerVehicle ? 6 : 4}>
                    {/* Colour Field */}
                    <TextField
                        fullWidth
                        disabled={readOnly}
                        label="Colour"
                        value={selectedManufacturerVehicle?.colour}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setSelectedManufacturerVehicle({
                                ...selectedManufacturerVehicle,
                                colour: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={newManufacturerVehicle ? 6 : 4}>
                    {/* RRP Field */}
                    <TextField
                        type="number"
                        fullWidth
                        disabled={readOnly}
                        label="RRP"
                        value={selectedManufacturerVehicle?.priceRRP}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            setSelectedManufacturerVehicle({
                                ...selectedManufacturerVehicle,
                                priceRRP: e.target.value
                            });
                        }}
                    />
                </Grid>
                {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                    <>
                        <Grid item xs={6}>
                            {/* Engine Size Field */}
                            <TextField
                                fullWidth
                                disabled={readOnly}
                                label="Engine Size"
                                value={selectedManufacturerVehicle?.engineSize}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) =>
                                    setSelectedManufacturerVehicle({
                                        ...selectedManufacturerVehicle,
                                        engineSize: e.target.value
                                    })
                                }
                            />
                        </Grid>{' '}
                        <Grid item xs={6}>
                            {/* Fuel Type Dropdown */}
                            <Autocomplete
                                isDisabled={readOnly}
                                options={specifics.fuelTypes}
                                useTwoOptionLabels={false}
                                primaryOptionLabel={'name'}
                                textfieldLabel="Fuel Type"
                                selectedValue={
                                    specifics.fuelTypes.find(
                                        (fule) =>
                                            fule.id ===
                                            selectedManufacturerVehicle?.FuelTypeId
                                    ) || null
                                }
                                handleSelectedValueChange={(event) =>
                                    setSelectedManufacturerVehicle({
                                        ...selectedManufacturerVehicle,
                                        FuelTypeId: event?.id,
                                        FuelType: event
                                    })
                                }
                                handleInputValueChange={() => null}
                            />
                        </Grid>
                    </>
                ) : null}

                <Grid item xs={12}>
                    {/* Description Field */}
                    <TextField
                        multiline
                        rows={5}
                        fullWidth
                        disabled={readOnly}
                        label="Notes"
                        value={selectedManufacturerVehicle?.description}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setSelectedManufacturerVehicle({
                                ...selectedManufacturerVehicle,
                                description: e.target.value
                            })
                        }
                    />
                </Grid>
            </Grid>

            <Fab
                customIcon={readOnly ? <Edit /> : <Save />}
                onClick={() => handleFabClick()}
                noSession={true}
            />
        </>
    );
};

export default withSnackbar(ManufacturerVehicleDrawerContent);
