import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { Service } from '../../../global/interfaces/ServiceInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleVehiclePresentCheckbox = (
    id: number,
    resultsList: Service[],
    setResultsList: Dispatch<SetStateAction<Service[]>>,
    showSnackbar: showSnackbar
) => {
    api.put('/vehiclePresent', { id: id }).then((res) => {
        if (res.status === 200) {
            let newResultsList = [...resultsList];
            let currentIndex = newResultsList.findIndex((x) => x.id === id);
            newResultsList[currentIndex].vehiclePresent =
                !newResultsList[currentIndex].vehiclePresent;

            setResultsList(newResultsList);
            showSnackbar('Service successfully updated.');
        } else {
            showSnackbar(
                'Something went wrong and we could not update the service.',
                'Please contact the ORA DMS teams.',
                'error'
            );
        }
    });
};

export default HandleVehiclePresentCheckbox;
