const CalculatePartTotal = (service) => {
  let total = 0;

  // Go through the added costs on the service first
  service.addedCosts.forEach((cost) => {
      if (cost.isFuel) {
          total += cost.rowTotal
              ? parseFloat(cost.rowTotal) / 1.1
              : parseFloat(cost.totalPrice) / 1.1;
      } else {
          total += cost.rowTotal
              ? parseFloat(cost.rowTotal)
              : parseFloat(cost.totalPrice);
      }
  });

  // Then go through the associated parts and accessories sales if applicable
  if (service.addedParts.length > 0) {
    service.addedParts.forEach((sale) => {
      sale.orderLines.forEach((cost) => {
        total += parseFloat(cost.totalPrice);
      });
    });
  }

  return total;
};

export default CalculatePartTotal;
