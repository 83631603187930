// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import { Grid } from '@mui/material';
// COMPONENTS
import Fab from '../../../../global/Fab';
import Options from './Options';
import AddressDetails from './AddressDetails';
import BasicDetails from './BasicDetails';
// LOGIC
import HandleUpdateCustomer from '../../logic/HandleUpdateCustomer';
// INTERFACES
import { Customer } from '../../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

interface BasicProps {
    customerInfo: Customer; // The customer's data
    setCustomerInfo: Dispatch<SetStateAction<Customer>>; // State setter for customerInfo
    disabled: boolean; // 'read-only' check
    setDisabled: Dispatch<SetStateAction<boolean>>; // State setter for disabled
    showSnackbar: showSnackbar; // State setter for ViewCustomer's snackbar
}

/**
 * Details
 * All content within ViewCustomer's 'Details' tab
 * @author Estienne
 * @param BasicProps
 * @returns Basic Details, Address Details and Options components
 */
const Details = ({
    customerInfo,
    setCustomerInfo,
    disabled,
    setDisabled,
    showSnackbar
}: BasicProps) => {
    const [loading, setLoading] = useState(false);

    const handleFabClick = () => {
        if (disabled) {
            setDisabled(false);
        } else {
            HandleUpdateCustomer({
                customerInfo: customerInfo,
                showSnackbar: showSnackbar,
                setReadOnly: setDisabled,
                setLoading: setLoading,
                refresh: true
            });
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <BasicDetails
                        customerInfo={customerInfo}
                        setCustomerInfo={setCustomerInfo}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <AddressDetails
                        customerInfo={customerInfo}
                        setCustomerInfo={setCustomerInfo}
                        disabled={disabled}
                    />
                    <br></br>
                    <Options
                        customerInfo={customerInfo}
                        setCustomerInfo={setCustomerInfo}
                        disabled={disabled}
                    />
                </Grid>
            </Grid>

            <Fab
                editIcon={disabled}
                loading={loading}
                onClick={handleFabClick}
            />
        </>
    );
};

export default Details;
