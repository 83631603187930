import { useState, useEffect } from 'react';
import {
    Typography,
    Divider,
    Grid,
    Fab,
    IconButton,
    Radio,
    Drawer,
    Box,
    Card,
    CardContent,
    Button,
    Paper,
    FormControl,
    RadioGroup,
    FormControlLabel
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';

// ICONS
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

// COMPONENT
import LeadsTable from './LeadsTable';
import NewEnquiry from './NewEnquiry';
import DataDisplay from './DataDisplay';
import { withSnackbar } from '../../../global/WrappingSnackbar';

// LOGIC
import GetManagerDashboardData from '../logic/GetManagerDashboardData';

// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Dayjs } from 'dayjs';
import { Site } from '../../../global/interfaces/GeneralInterface';
import { Make } from '../../../global/interfaces/VehicleSaleInterface';

const ManagerDashboard = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [activeDrawerOpen, setActiveDrawerOpen] = useState<boolean>(false);
    const [openNewEnquiry, setOpenNewEnquiry] = useState<boolean>(false);

    // Leads Data
    const [statusData, setStatusData] = useState<
        { id: number; label: string; total: number; value: number }[]
    >([]);
    const [makeData, setMakeData] = useState<
        { id: string; label: string; value: number }[]
    >([]);
    const [makeBreakdown, setMakeBreakdown] = useState<
        {
            id: number;
            name: string;
            active: number;
            lost: number;
            sold: number;
        }[]
    >([]);
    const [employeeBreakdown, setEmployeeBreakdown] = useState<
        {
            id: number;
            username: string;
            active: number;
            finance: number;
            sold: number;
            total: number;
        }[]
    >([]);
    const [sourceData, setSourceData] = useState<
        { id: string; label: string; value: number }[]
    >([]);
    const [totalEnquiry, setTotalEnquiry] = useState<number>(0);

    // Drawer Control
    const [selectedFilter, setSelectedFilter] = useState<{
        enquiryStatus: { type: string }[];
        assignedUser: { type: string; id: number }[];
        startDate: Dayjs;
        endDate: Dayjs;
        make: Make[];
        sites: Site[];
    }>();

    // Date Control
    const [dateCheck, setDateCheck] = useState<string>('allTime');
    const [startDate, setStartDate] = useState<Dayjs>(null);
    const [endDate, setEndDate] = useState<Dayjs>(null);

    useEffect(() => {
        GetManagerDashboardData(
            startDate,
            endDate,
            dateCheck,
            setStatusData,
            setSourceData,
            setMakeData,
            setMakeBreakdown,
            setEmployeeBreakdown,
            setTotalEnquiry
        );
    }, [startDate, endDate, dateCheck]);

    return (
        <div id="table">
            <br />
            <br />
            <Typography variant="h4" align="left">
                <IconButton
                    onClick={() =>
                        (window.location.href = '/vehicles/leadsmanagement')
                    }
                >
                    <ArrowBackIcon />
                </IconButton>
                Manager Dashboard
            </Typography>
            <br />
            <Paper>
                <Grid
                    container
                    columns={4}
                    spacing={1}
                    display="flex"
                    sx={{ marginBottom: 1 }}
                >
                    <Grid xs={4} md={2.5}>
                        <FormControl>
                            <RadioGroup
                                row
                                sx={{ marginTop: 1, marginBottom: 1 }}
                                value={dateCheck}
                                onChange={(event) => {
                                    setDateCheck(event.target.value);
                                }}
                            >
                                <FormControlLabel
                                    value={'allTime'}
                                    control={<Radio />}
                                    label="View all"
                                />
                                <FormControlLabel
                                    value="specificTime"
                                    control={<Radio />}
                                    label={'View between:'}
                                />
                            </RadioGroup>
                        </FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={startDate}
                                format="DD/MM/YYYY"
                                onChange={(newValue) => {
                                    if (newValue && newValue.isValid()) {
                                        setStartDate(newValue.startOf('day'));
                                    } else {
                                        setStartDate(null);
                                    }
                                }}
                                slotProps={{
                                    textField: {
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                                sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    width: 200
                                }}
                            />
                            <ArrowRightAltIcon
                                sx={{ marginTop: 2, marginBottom: 1 }}
                            />
                            <DatePicker
                                value={endDate}
                                format="DD/MM/YYYY"
                                onChange={(newValue) => {
                                    if (newValue && newValue.isValid()) {
                                        setEndDate(newValue.startOf('day'));
                                    } else {
                                        setEndDate(null);
                                    }
                                }}
                                slotProps={{
                                    textField: {
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                                sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    width: 200
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={2} md={0.75}>
                        <Button
                            sx={{ marginTop: 1 }}
                            onClick={() => {
                                setSelectedFilter({
                                    enquiryStatus: [
                                        { type: 'ACTIVE' },
                                        { type: 'PENDING' },
                                        { type: 'SOLD' },
                                        { type: 'LOST' }
                                    ],
                                    assignedUser: [],
                                    startDate: null,
                                    endDate: null,
                                    make: [],
                                    sites: []
                                });
                                setActiveDrawerOpen(true);
                            }}
                        >
                            View all enquiries
                        </Button>
                    </Grid>
                    <Grid xs={2} md={0.75}>
                        <Button
                            sx={{ marginTop: 1 }}
                            onClick={() => {
                                setSelectedFilter({
                                    enquiryStatus: [
                                        { type: 'ACTIVE' },
                                        { type: 'PENDING' },
                                        { type: 'SOLD' },
                                        { type: 'LOST' }
                                    ],
                                    assignedUser: [
                                        { type: 'No assigned user', id: 0 }
                                    ],
                                    startDate: null,
                                    endDate: null,
                                    make: [],
                                    sites: []
                                });
                                setActiveDrawerOpen(true);
                            }}
                        >
                            View unassigned enquiries
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper className="paper-padding">
                        <Typography variant="h6">
                            Total Enquiries: {totalEnquiry}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Card
                        variant="outlined"
                        sx={{ minWidth: 150, marginRight: 1 }}
                    >
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Active
                            </Typography>
                            {statusData.filter((x) => x.label === 'ACTIVE')
                                .length > 0 ? (
                                <Typography variant="h6" component="div">
                                    {
                                        statusData.filter(
                                            (x) => x.label === 'ACTIVE'
                                        )[0].total
                                    }
                                </Typography>
                            ) : (
                                <Typography variant="h6" component="div">
                                    0
                                </Typography>
                            )}
                        </CardContent>
                        <Button
                            onClick={() => {
                                setSelectedFilter({
                                    enquiryStatus: [{ type: 'ACTIVE' }],
                                    assignedUser: [],
                                    startDate: null,
                                    endDate: null,
                                    make: [],
                                    sites: []
                                });
                                setActiveDrawerOpen(true);
                            }}
                        >
                            View
                        </Button>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card
                        variant="outlined"
                        sx={{ minWidth: 150, marginLeft: 1, marginRight: 1 }}
                    >
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Pending
                            </Typography>
                            {statusData.filter((x) => x.label === 'PENDING')
                                .length > 0 ? (
                                <Typography variant="h6" component="div">
                                    {
                                        statusData.filter(
                                            (x) => x.label === 'PENDING'
                                        )[0].total
                                    }
                                </Typography>
                            ) : (
                                <Typography variant="h6" component="div">
                                    0
                                </Typography>
                            )}
                        </CardContent>
                        <Button
                            onClick={() => {
                                setSelectedFilter({
                                    enquiryStatus: [{ type: 'PENDING' }],
                                    assignedUser: [],
                                    startDate: null,
                                    endDate: null,
                                    make: [],
                                    sites: []
                                });
                                setActiveDrawerOpen(true);
                            }}
                        >
                            View
                        </Button>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card
                        variant="outlined"
                        sx={{ minWidth: 150, marginLeft: 1, marginRight: 1 }}
                    >
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Sold
                            </Typography>
                            {statusData.filter((x) => x.label === 'SOLD')
                                .length > 0 ? (
                                <Typography variant="h6" component="div">
                                    {
                                        statusData.filter(
                                            (x) => x.label === 'SOLD'
                                        )[0].total
                                    }
                                </Typography>
                            ) : (
                                <Typography variant="h6" component="div">
                                    0
                                </Typography>
                            )}
                        </CardContent>
                        <Button
                            onClick={() => {
                                setSelectedFilter({
                                    enquiryStatus: [{ type: 'SOLD' }],
                                    assignedUser: [],
                                    startDate: null,
                                    endDate: null,
                                    make: [],
                                    sites: []
                                });
                                setActiveDrawerOpen(true);
                            }}
                        >
                            View
                        </Button>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card
                        variant="outlined"
                        sx={{ minWidth: 150, marginLeft: 1 }}
                    >
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Lost
                            </Typography>
                            {statusData.filter((x) => x.label === 'LOST')
                                .length > 0 ? (
                                <Typography variant="h6" component="div">
                                    {
                                        statusData.filter(
                                            (x) => x.label === 'LOST'
                                        )[0].total
                                    }
                                </Typography>
                            ) : (
                                <Typography variant="h6" component="div">
                                    0
                                </Typography>
                            )}
                        </CardContent>
                        <Button
                            onClick={() => {
                                setSelectedFilter({
                                    enquiryStatus: [{ type: 'LOST' }],
                                    assignedUser: [],
                                    startDate: null,
                                    endDate: null,
                                    make: [],
                                    sites: []
                                });
                                setActiveDrawerOpen(true);
                            }}
                        >
                            View
                        </Button>
                    </Card>
                </Grid>

                <DataDisplay
                    statusData={statusData}
                    sourceData={sourceData}
                    makeData={makeData}
                    makeBreakdown={makeBreakdown}
                    employeeBreakdown={employeeBreakdown}
                    setSelectedFilter={setSelectedFilter}
                    setActiveDrawerOpen={setActiveDrawerOpen}
                />
            </Grid>

            <div
                style={{
                    margin: 0,
                    top: 'auto',
                    right: 20,
                    bottom: 20,
                    left: 'auto',
                    position: 'fixed',
                    zIndex: 1
                }}
            >
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={(e) => setOpenNewEnquiry(true)}
                >
                    <Add />
                </Fab>
            </div>

            <Drawer
                anchor={'right'}
                open={openNewEnquiry}
                onClose={() => setOpenNewEnquiry(false)}
            >
                <Box
                    sx={{ width: '95vw', padding: '20px' }}
                    role="presentation"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={11}>
                            <Typography variant="h4">New Enquiry</Typography>
                        </Grid>
                        <Grid item xs={1} textAlign={'right'}>
                            <IconButton
                                onClick={() => setOpenNewEnquiry(false)}
                            >
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Box height={'10px'}></Box>
                    <Divider></Divider>
                    <Box height="20px"></Box>
                    <NewEnquiry
                        selectedEnquiryId={null}
                        setSelectedEnquiryId={() => null}
                        setShouldBlockNavigation={() => null}
                        setDisabled={() => null}
                        status={null}
                        disabled={false}
                        showSnackbar={showSnackbar}
                    />
                </Box>
            </Drawer>

            <Drawer
                anchor={'left'}
                open={activeDrawerOpen}
                onClose={() => setActiveDrawerOpen(!activeDrawerOpen)}
            >
                <Box sx={{ width: '90vw' }}>
                    <LeadsTable
                        filter={selectedFilter}
                        setFilter={setSelectedFilter}
                    />
                </Box>
            </Drawer>
        </div>
    );
};

export default withSnackbar(ManagerDashboard);
