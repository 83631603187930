import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    IconButton,
    Grid
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Delete } from '@material-ui/icons';
import { CreateNewPoll, UpdatePoll } from '../logic/HandlePoll';
import { Poll } from '../../../global/interfaces/PollInterface';

interface NewPollDialogProps {
    open: boolean;
    onClose: () => void;
    showSnackbar: any;
    poll?: Poll;
}

const NewPollDialog = ({
    open,
    onClose,
    showSnackbar,
    poll
}: NewPollDialogProps) => {
    const [question, setQuestion] = useState('');
    const [options, setOptions] = useState(['', '']);

    useEffect(() => {
        if (poll) {
            setQuestion(poll.question);
            setOptions(poll.PollOptions.map((option) => option.content));
        } else {
            setQuestion('');
            setOptions(['', '']);
        }
    }, [poll]);

    const submitDisabled =
        question === '' ||
        options.some((option) => option === '') ||
        options.length < 2;

    const handleAddOption = () => {
        setOptions([...options, '']);
    };

    const handleRemoveOption = (index) => {
        const newOptions = options.filter((_, i) => i !== index);
        setOptions(newOptions);
    };

    const handleOptionChange = (index, value) => {
        const newOptions = [...options];
        newOptions[index] = value;
        setOptions(newOptions);
    };

    const handleSubmit = async () => {
        if (poll) {
            await UpdatePoll({
                pollId: poll.id,
                question,
                options,
                showSnackbar
            });
        } else {
            await CreateNewPoll({ question, options, showSnackbar });
        }
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>
                {poll ? 'Edit Poll' : 'Create a New Poll'}
            </DialogTitle>
            <DialogContent>
                <TextField
                    label="Question"
                    fullWidth
                    multiline
                    rows={2}
                    margin="normal"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                />
                {options.map((option, index) => (
                    <Grid container alignItems="center" spacing={1} key={index}>
                        <Grid item xs>
                            <TextField
                                label={`Option ${index + 1}`}
                                fullWidth
                                margin="normal"
                                value={option}
                                onChange={(e) =>
                                    handleOptionChange(index, e.target.value)
                                }
                            />
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={() => handleRemoveOption(index)}
                            >
                                <Delete />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
                <Button
                    startIcon={<AddIcon />}
                    onClick={handleAddOption}
                    color="primary"
                >
                    Add option
                </Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    disabled={submitDisabled}
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                >
                    {poll ? 'Update' : 'Submit'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewPollDialog;
