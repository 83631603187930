// IMPORTS
import { useEffect, useState, createContext } from 'react';
import {
    Toolbar,
    Backdrop,
    CircularProgress,
    CssBaseline
} from '@mui/material';
import {
    Switch,
    Route,
    Redirect,
    useLocation,
    matchPath
} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import jwtDecode from 'jwt-decode';
import './Main.css';
import api from '../../api';

// COMPONENTS
import Sidebar from '../OraNextGen/sidebar/Sidebar';
import Header from '../OraNextGen/header/Header';

// LOGIC
import useToken from './useToken';
import socket from '../../context/socket';
import RoleCheck from '../OraNextGen/global/RoleCheck';
import GetSite from '../OraNextGen/global/databaseLogic/GetSite';
import GetUserSettings from '../OraNextGen/global/databaseLogic/GetUserSettings';

// GENERAL
import Login from '../OraNextGen/general/login/Login';
import HomePage from '../OraNextGen/general/homePage/HomePage';
import ResetPassword from '../OraNextGen/general/password/ResetPassword';

// CUSTOMER
import CustomerTable from '../OraNextGen/general/customer/CustomerTable.tsx';
import ViewCustomer from '../OraNextGen/general/customer/ViewCustomer';

// TEXT AND EMAIL
import TemplateTable from '../OraNextGen/general/template/TemplateTable';
import Template from '../OraNextGen/general/template/ViewTemplate';

// CREDITOR
import CreditorTable from '../OraNextGen/general/creditor/CreditorTable';
import ViewCreditor from '../OraNextGen/general/creditor/ViewCreditor';
import AddCreditor from '../OraNextGen/general/creditor/AddCreditor';

// USER
import UserSettings from '../OraNextGen/general/userSettings/UserSettings';
import UserTable from '../OraNextGen/general/user/UserTable';
import ViewUser from '../OraNextGen/general/user/ViewUser';

// SITE
import SiteTable from '../OraNextGen/general/site/SiteTable';
import ViewSite from '../OraNextGen/general/site/ViewSite';

// MISC
import OnboardingUI from './Onboarding/OnboardingUI';
import ApiUITS from '../OraNextGen/general/api/ApiUITS';
import IPWhitelistUI from '../OraNextGen/general/ipWhitelist/IPWhitelistUI';
import ViewCompany from '../OraNextGen/general/company/ViewCompany';
import SpecificsUI from '../OraNextGen/general/specifics/SpecificsUI';
import TillReconciliation from '../OraNextGen/general/tillReconciliation/TillReconciliation';

// DAILY TASKS
import DailyTaskPage from '../OraNextGen/general/dailyTasks/DailyTaskPage';

// DEPARTMENT ROUTERS
import AdminRouter from '../OraNextGen/routers/AdminRouter';
import PartsRouter from '../OraNextGen/routers/PartsRouter';
import ServiceRouter from '../OraNextGen/routers/ServiceRouter';
import VehicleRouter from '../OraNextGen/routers/VehicleRouter';
import OnlineRouter from '../OraNextGen/routers/OnlineRouter';

// DOCUMENTATION ROUTER
import DocumentationRouter from '../OraNextGen/routers/DocumentationRouter';

// DEALER SIGN CONTRACT PAGE
import DealerSignContract from '../OraNextGen/vehicle/signContract/DealerSignContract';

// DEALER SIGN VENDOR STATEMENT PAGE
import VerifyCustomer from '../OraNextGen/esign/components/VerifyCustomer';
import DealerTradeDocManager from '../OraNextGen/vehicle/signVendorStatement/DealerTradeDocManager';

// CUSTOMER SIGN VENDOR STATEMENT PAGE
import CustomerTradeDocManager from '../OraNextGen/vehicle/signVendorStatement/CustomerTradeDocManager';

// CUSTOMER SIGN LOA PAGE
import CustomerLOAPage from '../OraNextGen/vehicle/signLOA/CustomerLOAPage';

// CUSTOMER SIGN CONTRACT PAGE
import CustomerContractPage from '../OraNextGen/vehicle/signContract/CustomerContractPage';
import EsignPage from '../OraNextGen/esign/EsignPage';

//  customer sign test page
import SignerPage from '../OraNextGen/esign/SignerPage';
import VerifySigner from '../OraNextGen/esign/components/VerifySigner';
import CustomerPersonalisedPlateFormPage from '../OraNextGen/vehicle/signPersonalisedPlateForm/CustomerPersonalisedPlateFormPage';

const theme = createTheme({
    palette: {
        primary: {
            light: '#a88ce2',
            main: '#9370DB',
            dark: '#8464c5',
            contrastText: '#fff'
        },
        background: {
            default: '#f5f5f5',
            paper: '#fff'
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {
                    shrink: true
                }
            }
        },
        MuiSpeedDial: {
            defaultProps: {
                sx: { position: 'fixed', bottom: 16, right: 16 }
            }
        },
        MuiAvatar: {
            defaultProps: {
                sx: { backgroundColor: '#444343' }
            }
        }
    }
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#5f3ba8',
            contrastText: '#fff'
        },
        error: {
            main: '#AE331A'
        },
        warning: {
            main: '#D86B05'
        },
        background: {
            default: '#1f1d1d',
            paper: '#212020'
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {
                    shrink: true
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.MuiInputLabel-shrink': {
                        '&.Mui-disabled': {
                            color: '#fff',
                            opacity: '0.45'
                        },
                        color: '#9370DB'
                    }
                }
            }
        },
        MuiSpeedDial: {
            defaultProps: {
                sx: { position: 'fixed', bottom: 16, right: 16 }
            }
        },
        MuiIconButton: {
            defaultProps: {
                sx: { color: '#9370DB' }
            }
        },
        MuiButton: {
            defaultProps: {
                sx: {
                    color: '#ffffff',
                    borderColor: '#a88ce2'
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: '#a88ce2'
                    }
                }
            }
        },
        MuiTypography: {
            defaultProps: {
                sx: {
                    color: '#ffffff'
                }
            }
        }
    }
});

// Slightly change Ora's colors when in the Support section
if (window.location.href.includes('/support')) {
    darkTheme.palette.background = {
        default: '#34495E',
        paper: '#283747'
    };
    theme.palette.background = {
        default: '#AEB6BF',
        paper: '#85929E'
    };
    theme.components.MuiAvatar = {
        defaultProps: {
            sx: {
                backgroundColor: '#85929E',
                color: 'black'
            }
        }
    };
}

export const PermissionsContext = createContext([]);
export const SiteContext = createContext({});
export const UserContext = createContext({});

export default function MainComponent() {
    const { token, setToken } = useToken();
    const [permissions, setPermissions] = useState([]);
    const [currentUser, setCurrentUser] = useState({});
    const [currentUserSettings, setCurrentUserSettings] = useState({});

    const [backdropOpen, setBackdropOpen] = useState(false);
    const [currentTheme, setCurrentTheme] = useState(darkTheme);
    const [siteData, setSiteData] = useState({ departments: [] });
    const [loggedIn, setLoggedIn] = useState(true);
    const [customerLoggedIn, setCustomerLoggedIn] = useState(true);

    let location = useLocation();

    useEffect(() => {
        if (!location.pathname.includes('/esign/')) {
            GetSite(parseInt(localStorage.getItem('SiteId')), setSiteData);
            let decoded;
            try {
                if (token) {
                    decoded = jwtDecode(token);
                }
            } catch (error) {
                decoded = false;
            }

            if (
                decoded &&
                decoded.exp > Date.now() / 1000 &&
                decoded?.id != null
            ) {
                socket.emit('login', [decoded.id, '/']);
                api.get('userPermission').then((data) => {
                    setPermissions(data.data);
                });
                if (window.location.pathname.split('/')[1] !== 'login') {
                    GetUserSettings(setCurrentUserSettings, setCurrentUser);
                }
            } else {
                setLoggedIn(false);
                localStorage.setItem('SiteId', null);
                localStorage.setItem('token', null);
            }
        }
        // eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        if (location.pathname.includes('/esign/')) {
            let decoded;
            try {
                if (token) {
                    decoded = jwtDecode(token);
                }
            } catch (error) {
                decoded = false;
            }

            if (
                decoded &&
                decoded.exp > Date.now() / 1000 &&
                decoded?.id != null
            ) {
                setCustomerLoggedIn(true);
            } else {
                setCustomerLoggedIn(false);
                localStorage.setItem('token', null);
            }
        }
    }, [token, location.pathname]);

    useEffect(() => {
        // If there is no SiteId in local storage: user isn't logged in
        // Or if is on esign page
        // No need to track where he is with the socket
        if (
            localStorage.getItem('SiteId') !== 'null' &&
            !location.pathname.includes('/esign/')
        ) {
            socket.emit('location', location.pathname);
        } else if (
            localStorage.getItem('SiteId') == null &&
            !location.pathname.includes('/esign/')
        ) {
            localStorage.setItem('SiteId', currentUser.SiteId.toString());
        }
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        if (currentUserSettings.darkMode) {
            setCurrentTheme(darkTheme);
        } else {
            setCurrentTheme(theme);
        }
    }, [currentUserSettings]);

    var showHeaderSideBar =
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/onboarding' &&
        window.location.pathname.includes('/esign/') === false;

    function ProtectedRoute(props) {
        return (
            <Route exact={props.exact} path={props.path}>
                {props.loggedIn ? (
                    props.children
                ) : (
                    <Redirect to={{ pathname: '/login' }} />
                )}
            </Route>
        );
    }

    // =================================THIS NEED TO BE IMPROVED================================================================
    function ProtectedCustomerRoute(props) {
        const location = useLocation();
        const params = new URLSearchParams(location.search);
        const [isLoading, setIsLoading] = useState(true);

        useEffect(() => {
            if (location.pathname.includes('/esign/')) {
                let decoded;
                try {
                    if (token) {
                        decoded = jwtDecode(token);
                    }
                } catch (error) {
                    decoded = false;
                }

                if (
                    decoded &&
                    decoded.exp > Date.now() / 1000 &&
                    decoded?.id != null
                ) {
                    props.setCustomerLoggedIn(true);
                } else {
                    props.setCustomerLoggedIn(false);
                    localStorage.setItem('token', null);
                }
            }

            setIsLoading(false);
            // eslint-disable-next-line
        }, [props]);

        if (isLoading) {
            return null;
        }

        const isSignerPage = matchPath(location.pathname, {
            path: '/esign/test',
            exact: true,
            strict: false
        });

        return (
            <Route exact={props.exact} path={props.path}>
                {props.customerLoggedIn ? (
                    props.children
                ) : isSignerPage ? (
                    <Redirect
                        to={{
                            pathname: `/esign/verify/${params.get('document')}`,
                            search: `?redirect=${encodeURIComponent(location.pathname + location.search)}`
                        }}
                    />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/esign/login',
                            search: `?redirect=${encodeURIComponent(location.pathname + location.search)}`
                        }}
                    />
                )}
            </Route>
        );
    }

    return (
        <div id="main">
            <SiteContext.Provider value={siteData}>
                <PermissionsContext.Provider value={permissions}>
                    <UserContext.Provider value={currentUser}>
                        <ThemeProvider theme={currentTheme}>
                            <CssBaseline>
                                {showHeaderSideBar ? (
                                    <>
                                        <Header
                                            showUpdates={
                                                currentUser.needRefresh
                                            }
                                            setBackdropOpen={setBackdropOpen}
                                        />
                                        <Sidebar />
                                    </>
                                ) : null}
                                <main className="content">
                                    {showHeaderSideBar ? <Toolbar /> : null}
                                    <div className="main-content">
                                        <Switch>
                                            {/* GENERAL */}
                                            <Route path="/login">
                                                <ThemeProvider theme={theme}>
                                                    <Login
                                                        setToken={setToken}
                                                    />
                                                </ThemeProvider>
                                            </Route>
                                            <Route path="/onboarding">
                                                <ThemeProvider theme={theme}>
                                                    <OnboardingUI />
                                                </ThemeProvider>
                                            </Route>
                                            <ProtectedRoute
                                                exact
                                                path="/eSign/create"
                                                loggedIn={loggedIn}
                                            >
                                                <EsignPage />
                                            </ProtectedRoute>

                                            <ProtectedRoute
                                                exact
                                                path="/"
                                                loggedIn={loggedIn}
                                            >
                                                <HomePage />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path="/resetPassword"
                                                loggedIn={loggedIn}
                                            >
                                                <ResetPassword />
                                            </ProtectedRoute>

                                            <ProtectedRoute
                                                path={`/docs/vehicle-contract/:id`}
                                                loggedIn={loggedIn}
                                            >
                                                <DealerSignContract />
                                            </ProtectedRoute>

                                            <ProtectedRoute
                                                path={`/docs/vendor-statement/:id`}
                                                loggedIn={loggedIn}
                                            >
                                                <DealerTradeDocManager />
                                            </ProtectedRoute>

                                            {/* Public route for customer login */}
                                            <Route path={`/esign/login`}>
                                                <VerifyCustomer
                                                    setToken={setToken}
                                                />
                                            </Route>

                                            {/* Public route for signer verify access code */}
                                            <Route
                                                path={`/esign/verify/:documentId`}
                                            >
                                                <VerifySigner
                                                    setToken={setToken}
                                                />
                                            </Route>

                                            {/* Public Route for Signer Page */}
                                            <ProtectedCustomerRoute
                                                path={`/esign/test`}
                                                customerLoggedIn={
                                                    customerLoggedIn
                                                }
                                                setCustomerLoggedIn={
                                                    setCustomerLoggedIn
                                                }
                                            >
                                                <SignerPage />
                                            </ProtectedCustomerRoute>

                                            {/* Public Route for Customer Sign Contract */}
                                            <ProtectedCustomerRoute
                                                path={`/esign/vehicle-contract/:id`}
                                                customerLoggedIn={
                                                    customerLoggedIn
                                                }
                                                setCustomerLoggedIn={
                                                    setCustomerLoggedIn
                                                }
                                            >
                                                <CustomerContractPage />
                                            </ProtectedCustomerRoute>

                                            {/* Protected Route for Customer Sign Vendor Statement */}
                                            <ProtectedCustomerRoute
                                                path={`/esign/vendor-statement/:id`}
                                                customerLoggedIn={
                                                    customerLoggedIn
                                                }
                                                setCustomerLoggedIn={
                                                    setCustomerLoggedIn
                                                }
                                            >
                                                <CustomerTradeDocManager />
                                            </ProtectedCustomerRoute>

                                            {/* Protected Route for Customer Sign LOA */}
                                            <ProtectedCustomerRoute
                                                path={`/esign/loa/:id`}
                                                customerLoggedIn={
                                                    customerLoggedIn
                                                }
                                                setCustomerLoggedIn={
                                                    setCustomerLoggedIn
                                                }
                                            >
                                                <CustomerLOAPage />
                                            </ProtectedCustomerRoute>

                                            {/* Protected Route for Customer Sign personalised plate form */}
                                            <ProtectedCustomerRoute
                                                path={`/esign/personalised_plate_form/:id`}
                                                customerLoggedIn={
                                                    customerLoggedIn
                                                }
                                                setCustomerLoggedIn={
                                                    setCustomerLoggedIn
                                                }
                                            >
                                                <CustomerPersonalisedPlateFormPage />
                                            </ProtectedCustomerRoute>

                                            {/* CUSTOMTER */}
                                            <ProtectedRoute
                                                path="/customers"
                                                loggedIn={loggedIn}
                                            >
                                                <CustomerTable />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path={`/viewCustomer/:id`}
                                                loggedIn={loggedIn}
                                            >
                                                <ViewCustomer />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path={`/addCustomer`}
                                                loggedIn={loggedIn}
                                            >
                                                <ViewCustomer />
                                            </ProtectedRoute>

                                            {/* TEXT AND EMAIL */}
                                            <ProtectedRoute
                                                path="/templates"
                                                loggedIn={loggedIn}
                                            >
                                                <TemplateTable />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path="/addTemplate"
                                                loggedIn={loggedIn}
                                            >
                                                <Template />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path="/viewTemplate/:id"
                                                loggedIn={loggedIn}
                                            >
                                                <Template />
                                            </ProtectedRoute>

                                            {/* CREDITOR */}
                                            <ProtectedRoute
                                                path="/creditors"
                                                loggedIn={loggedIn}
                                            >
                                                <RoleCheck
                                                    component={
                                                        <CreditorTable />
                                                    }
                                                    permission={
                                                        'access_creditors'
                                                    }
                                                    pageError
                                                />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path={`/viewCreditor/:id`}
                                                loggedIn={loggedIn}
                                            >
                                                <RoleCheck
                                                    component={<ViewCreditor />}
                                                    permission={
                                                        'access_creditors'
                                                    }
                                                    pageError
                                                />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path={`/addCreditor`}
                                                loggedIn={loggedIn}
                                            >
                                                <RoleCheck
                                                    component={<AddCreditor />}
                                                    permission={'add_creditor'}
                                                    pageError
                                                />
                                            </ProtectedRoute>

                                            {/* USER */}
                                            <ProtectedRoute
                                                path="/userSettings"
                                                loggedIn={loggedIn}
                                            >
                                                <UserSettings
                                                    currentUser={currentUser}
                                                    setCurrentUser={
                                                        setCurrentUser
                                                    }
                                                />
                                            </ProtectedRoute>

                                            {/* DAILYTASKS */}
                                            <ProtectedRoute
                                                path="/dailytask"
                                                loggedIn={loggedIn}
                                            >
                                                <DailyTaskPage />
                                            </ProtectedRoute>

                                            <ProtectedRoute
                                                path={`/configureUsers`}
                                                loggedIn={loggedIn}
                                            >
                                                <UserTable />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path={`/viewUser/:id`}
                                                loggedIn={loggedIn}
                                            >
                                                <ViewUser />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path={`/addUser`}
                                                loggedIn={loggedIn}
                                            >
                                                <RoleCheck
                                                    component={<ViewUser />}
                                                    permission={
                                                        'configure_users, configure_personal_users'
                                                    }
                                                    pageError
                                                />
                                            </ProtectedRoute>

                                            {/* SITE */}
                                            <ProtectedRoute
                                                path="/sites"
                                                loggedIn={loggedIn}
                                            >
                                                <RoleCheck
                                                    component={<SiteTable />}
                                                    permission={
                                                        'configure_sites'
                                                    }
                                                    pageError
                                                />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path="/viewSite/:id"
                                                loggedIn={loggedIn}
                                            >
                                                <RoleCheck
                                                    component={<ViewSite />}
                                                    permission={
                                                        'configure_sites'
                                                    }
                                                    pageError
                                                />
                                            </ProtectedRoute>

                                            <ProtectedRoute
                                                path="/addSite"
                                                loggedIn={loggedIn}
                                            >
                                                <RoleCheck
                                                    component={<ViewSite />}
                                                    permission={
                                                        'configure_sites'
                                                    }
                                                    pageError
                                                />
                                            </ProtectedRoute>

                                            {/* MISC */}
                                            <ProtectedRoute
                                                path="/apiSettings"
                                                loggedIn={loggedIn}
                                            >
                                                <RoleCheck
                                                    component={<ApiUITS />}
                                                    permission={'configure_api'}
                                                    pageError
                                                />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path="/ipWhitelist"
                                                loggedIn={loggedIn}
                                            >
                                                <RoleCheck
                                                    component={
                                                        <IPWhitelistUI />
                                                    }
                                                    permission={
                                                        'access_ipWhitelist'
                                                    }
                                                    pageError
                                                />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path="/companySettings"
                                                loggedIn={loggedIn}
                                            >
                                                <RoleCheck
                                                    component={<ViewCompany />}
                                                    permission={
                                                        'configure_company'
                                                    }
                                                    pageError
                                                />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path="/specifics"
                                                loggedIn={loggedIn}
                                            >
                                                <SpecificsUI />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path="/tillReconciliation"
                                                loggedIn={loggedIn}
                                            >
                                                <TillReconciliation />
                                            </ProtectedRoute>

                                            {/* DEPARTMENT ROUTERS */}
                                            <ProtectedRoute
                                                path="/admin"
                                                loggedIn={loggedIn}
                                            >
                                                <AdminRouter />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path="/inventory"
                                                loggedIn={loggedIn}
                                            >
                                                <PartsRouter />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path="/online"
                                                loggedIn={loggedIn}
                                            >
                                                <OnlineRouter />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path="/vehicles"
                                                loggedIn={loggedIn}
                                            >
                                                <VehicleRouter />
                                            </ProtectedRoute>
                                            <ProtectedRoute
                                                path="/service"
                                                loggedIn={loggedIn}
                                            >
                                                <ServiceRouter />
                                            </ProtectedRoute>

                                            {/* DOCUMENTATION ROUTER */}
                                            <ProtectedRoute
                                                path="/support"
                                                loggedIn={loggedIn}
                                            >
                                                <DocumentationRouter />
                                            </ProtectedRoute>
                                        </Switch>
                                    </div>
                                </main>
                                <Backdrop
                                    sx={{
                                        color: '#fff',
                                        zIndex: (theme) =>
                                            theme.zIndex.drawer + 1
                                    }}
                                    open={backdropOpen}
                                    onClick={() => null}
                                >
                                    <CircularProgress
                                        color="inherit"
                                        size={60}
                                    />
                                </Backdrop>
                            </CssBaseline>
                        </ThemeProvider>
                    </UserContext.Provider>
                </PermissionsContext.Provider>
            </SiteContext.Provider>
        </div>
    );
}
