import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { VehicleTrustPayment } from '../../../global/interfaces/VehicleSaleInterface';

const GetTrustDetails = (
  VehicleId: number,
  setTrustDetails: Dispatch<SetStateAction<VehicleTrustPayment[]>>
) => {
  api.get(`trustReceiptDetails/${VehicleId}`).then((res) => {
      setTrustDetails(res.data.trustDetails);
  });
};

export default GetTrustDetails;
