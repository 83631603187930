import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleUnreconcileTransactionManualSelection = (
    manualTransactionId: number,
    reconciliationId: number,
    bankLines: number[],
    showSnackbar: showSnackbar
) => {
    api.put('unreconcileManualTransactionManualSelection', {
        manualTransactionId: manualTransactionId,
        reconciliationId: reconciliationId,
        bankTransactionIds: bankLines
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Transaction Unreconciled.',
                'The page will now refresh.'
            );
            setTimeout(() => window.location.reload(), 1000);
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};
export default HandleUnreconcileTransactionManualSelection;
