import api from '../../../../../api';

const updateVehicleSaleDocStatus = async (
    vehicelSaleId: number,
    statusName: string,
    statusValue: string | null
) => {
    const res = await api.put(`updateVehicleSaleDocStatus`, {
        vehicelSaleId,
        statusName,
        statusValue
    });

    return res;
};

export default updateVehicleSaleDocStatus;
